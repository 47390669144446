import { LayoutComponent } from './layout/layout.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { PolicyLayoverComponent } from './policy-layover/policy-layover.component';
import { iframeSettingsProvider } from './iframe';
var COMPONENTS = [
    LayoutComponent,
    FooterComponent,
    HeaderComponent,
    PolicyLayoverComponent
];
var ɵ0 = iframeSettingsProvider, ɵ1 = typeof window !== 'undefined' ? window.localStorage : { setItem: function () { }, getItem: function () { } };
var CoreModule = /** @class */ (function () {
    function CoreModule() {
    }
    return CoreModule;
}());
export { CoreModule };
export { ɵ0, ɵ1 };
