import { Component, Inject, PLATFORM_ID, AfterViewInit } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'svi-faq',
  templateUrl: './faq.page.html',
  styleUrls: ['./faq.page.scss']
})
export class FaqPage implements AfterViewInit {
  constructor(@Inject(PLATFORM_ID) private platformId) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0)
    }
  }
}
