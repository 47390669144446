import * as tslib_1 from "tslib";
import { AnimationEvent } from '@angular/animations';
import { CdkAccordionItem } from '@angular/cdk/accordion';
/** Time and timing curve for expansion panel animations. */
export var EXPANSION_PANEL_ANIMATION_TIMING = '225ms cubic-bezier(0.4,0.0,0.2,1)';
var AccordionItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AccordionItemComponent, _super);
    function AccordionItemComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AccordionItemComponent.prototype._getExpandedState = function () {
        return this.expanded ? 'expanded' : 'collapsed';
    };
    /* From angular material */
    AccordionItemComponent.prototype._bodyAnimation = function (event) {
        var classList = event.element.classList;
        var cssClass = 'expanded';
        var phaseName = event.phaseName, toState = event.toState;
        // Toggle the body's `overflow: hidden` class when closing starts or when expansion ends in
        // order to prevent the cases where switching too early would cause the animation to jump.
        // Note that we do it directly on the DOM element to avoid the slight delay that comes
        // with doing it via change detection.
        if (phaseName === 'done' && toState === 'expanded') {
            classList.add(cssClass);
        }
        else if (phaseName === 'start' && toState === 'collapsed') {
            classList.remove(cssClass);
        }
    };
    return AccordionItemComponent;
}(CdkAccordionItem));
export { AccordionItemComponent };
