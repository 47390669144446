import { Component, Inject, Sanitizer, AfterViewInit } from '@angular/core'
import { CONTAINER_DATA } from '../sharing-trigger.directive'
import { SharingInfoDirective } from '../sharing-info.directive'
import { SharingService } from '../sharing.service'

@Component({
  selector: 'svi-sharing-overlay',
  templateUrl: './sharing-overlay.component.html',
  styleUrls: ['./sharing-overlay.component.scss']
})
export class SharingOverlayComponent {
  sharingInfo: SharingInfoDirective

  constructor(@Inject(CONTAINER_DATA) public data: any, public sharingService: SharingService) {
    this.sharingInfo = data.sharingInfo
  }
}
