import * as tslib_1 from "tslib";
import { AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { combineLatest } from 'rxjs';
import { VoterIdService } from '../core/voter-id.service';
import { isPlatformBrowser } from '@angular/common';
var _a = require('graphql-tag/loader!./details.page.graphql'), CandidateDetailsWithVoter = _a.CandidateDetailsWithVoter, CandidateDetails = _a.CandidateDetails;
var CandidateDetailsSharePage = /** @class */ (function () {
    function CandidateDetailsSharePage(route, router, voterIdService, apollo, platformId) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.apollo = apollo;
        this.platformId = platformId;
        this.tabIndex = 0;
        this.data = combineLatest([route.params, route.queryParams]).pipe(switchMap(function (_a) {
            var params = _a[0], queryParams = _a[1];
            _this.tabIndex = parseInt(queryParams['tab'], 10) || 0;
            if (queryParams.rid) {
                return _this._getQueryWithVoter(params.id, queryParams.rid, params.electionId);
            }
            else {
                return _this._getQueryWithoutVoter(params.id, params.electionId);
            }
        }), map(function (_a) {
            var data = _a.data;
            var _b = data, candidate = _b.candidate, election = _b.election, questionnaire = _b.questionnaire, recommendation = _b.recommendation;
            // Deep copy hack for issue: https://github.com/apollographql/apollo-angular/issues/329
            if (recommendation) {
                return candidate
                    ? {
                        candidate: tslib_1.__assign({}, candidate, { smartspider: tslib_1.__assign({}, candidate.smartspider, { options: {} }) }),
                        election: tslib_1.__assign({}, election),
                        questions: questionnaire.questions.slice(),
                        voter: tslib_1.__assign({}, recommendation.voter, { isMe: voterIdService.getVoterId() === recommendation.voter.id })
                    }
                    : {};
            }
            else {
                return candidate
                    ? {
                        candidate: tslib_1.__assign({}, candidate, { smartspider: tslib_1.__assign({}, candidate.smartspider, { options: {} }) }),
                        election: tslib_1.__assign({}, election),
                        questions: questionnaire.questions.slice()
                    }
                    : {};
            }
        }));
    }
    CandidateDetailsSharePage.prototype.ngAfterViewInit = function () {
        if (isPlatformBrowser(this.platformId)) {
            window.scroll(0, 0);
        }
    };
    CandidateDetailsSharePage.prototype.onTabChanged = function (index) {
        this._updateQueryParams({ tab: index });
    };
    CandidateDetailsSharePage.prototype._updateQueryParams = function (params) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
            queryParamsHandling: 'merge'
        });
    };
    CandidateDetailsSharePage.prototype._getQueryWithVoter = function (candidateId, recommendationId, electionId) {
        return this.apollo.query({
            query: CandidateDetailsWithVoter,
            variables: { candidateId: candidateId, electionId: electionId, recommendationId: recommendationId }
        });
    };
    CandidateDetailsSharePage.prototype._getQueryWithoutVoter = function (candidateId, electionId) {
        return this.apollo.query({
            query: CandidateDetails,
            variables: { candidateId: candidateId, electionId: electionId }
        });
    };
    return CandidateDetailsSharePage;
}());
export { CandidateDetailsSharePage };
