import { CdkAccordionModule } from '@angular/cdk/accordion'
import { CommonModule } from '@angular/common'
import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core'
import { SharedModule } from '@shared/shared.module'
import { QuestionnaireActionsComponent } from './actions/actions.component'
import { QuestionnaireAnswerComponent } from './answer/answer.component'
import { QuestionnaireNavigationComponent } from './navigation/questionnare-navigation.component'
import { QuestionnaireQuestionComponent } from './question/question.component'
import { QuestionnairePage } from './questionnaire.page'
import { QuestionnaireModuleConfiguration } from './questionnaire.module.configuration'

export const QUESTIONNAIRE_MODULE_CONFIG = new InjectionToken<QuestionnaireModuleConfiguration>(
  'QuestionnaireModuleConfiguration'
)

const COMPONENTS = [
  QuestionnaireNavigationComponent,
  QuestionnaireQuestionComponent,
  QuestionnaireActionsComponent,
  QuestionnaireAnswerComponent,
  QuestionnairePage
]

@NgModule({
  imports: [CommonModule, SharedModule, CdkAccordionModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class QuestionnaireModule {
  public static forRoot(config: QuestionnaireModuleConfiguration): ModuleWithProviders {
    return {
      ngModule: QuestionnaireModule,
      providers: [{ provide: QUESTIONNAIRE_MODULE_CONFIG, useValue: config }]
    }
  }
}
