import { InjectionToken } from '@angular/core'
import { TransferState, makeStateKey } from '@angular/platform-browser'

export const IFRAME_SETTINGS_KEY = makeStateKey('iframe-settings-state')

export interface IframeSettings {
  /**
   * Enabled if inside iframe.
   */
  enabled: boolean
  id: string
}

export const IFRAME_SETTINGS = new InjectionToken<IframeSettings>('IFRAME_SETTINGS')

export function iframeSettingsProvider(state: TransferState): IframeSettings {
  return state.get(IFRAME_SETTINGS_KEY, { enabled: false, id: undefined })
}
