import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'svi-chart-legend-item',
  templateUrl: './chart-legend-item.component.html'
})
export class ChartLegendItemComponent implements OnInit {
  @Input() label: string
  @Input() color: string

  constructor() {}

  ngOnInit() {}
}
