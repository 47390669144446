import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '../shared/shared.module'

import { CandidateDetailsPage } from './candidate-details.page'
import { CandidateDetailsSharePage } from './candidate-details-share.page'
import { PartyDetailsPage } from './party-details.page'
import { PartyDetailsSharePage } from './party-details-share.page'
import { ProfileModule } from '../profile/profile.module'
import { environment } from '../../environments/environment'

const COMPONENTS = [
  CandidateDetailsPage,
  CandidateDetailsSharePage,
  PartyDetailsPage,
  PartyDetailsSharePage
]

@NgModule({
  imports: [CommonModule,
    SharedModule,
    ProfileModule.forRoot(environment.profileModuleConfiguration)],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ResponderDetailsModule { }
