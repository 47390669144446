import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core'

@Component({
  selector: 'svi-tab',
  templateUrl: 'tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TabComponent {
  /** The plain text label for the tab, used when there is no template label. */
  // tslint:disable-next-line
  @Input('label') label: string = ''
  @ViewChild(TemplateRef) content: TemplateRef<any>

  /**
   * The relatively indexed position where 0 represents the center, negative is left, and positive
   * represents the right.
   */
  position: number | null = null

  /**
   * The initial relatively index origin of the tab if it was created and selected after there
   * was already a selected tab. Provides context of what position the tab should originate from.
   */
  origin: number | null = null

  /**
   * Whether the tab is currently active.
   */
  isActive = false
}
