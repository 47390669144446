export default
[
    {
        "name": "Banks",
        "district": "Brindabella"
    },
    {
        "name": "Bonython",
        "district": "Brindabella"
    },
    {
        "name": "Booth District",
        "district": "Brindabella"
    },
    {
        "name": "Calwell",
        "district": "Brindabella"
    },
    {
        "name": "Chisholm",
        "district": "Brindabella"
    },
    {
        "name": "Conder",
        "district": "Brindabella"
    },
    {
        "name": "Cotter Dam",
        "district": "Brindabella"
    },
    {
        "name": "Fadden",
        "district": "Brindabella"
    },
    {
        "name": "Gilmore",
        "district": "Brindabella"
    },
    {
        "name": "Gordon",
        "district": "Brindabella"
    },
    {
        "name": "Gowrie",
        "district": "Brindabella"
    },
    {
        "name": "Greenway",
        "district": "Brindabella"
    },
    {
        "name": "Isabella Plains",
        "district": "Brindabella"
    },
    {
        "name": "Kambah (west of Drakeford Dr)",
        "district": "Brindabella"
    },
    {
        "name": "Macarthur",
        "district": "Brindabella"
    },
    {
        "name": "Melrose Valley",
        "district": "Brindabella"
    },
    {
        "name": "Monash",
        "district": "Brindabella"
    },
    {
        "name": "Mount Clear district",
        "district": "Brindabella"
    },
    {
        "name": "Oxley",
        "district": "Brindabella"
    },
    {
        "name": "Paddy's River District",
        "district": "Brindabella"
    },
    {
        "name": "Rendezvous Creek District",
        "district": "Brindabella"
    },
    {
        "name": "Richardson",
        "district": "Brindabella"
    },
    {
        "name": "Royalla",
        "district": "Brindabella"
    },
    {
        "name": "Tennent District",
        "district": "Brindabella"
    },
    {
        "name": "Tharwa",
        "district": "Brindabella"
    },
    {
        "name": "Theodore",
        "district": "Brindabella"
    },
    {
        "name": "Tuggeranong",
        "district": "Brindabella"
    },
    {
        "name": "Tuggeranong District Sth",
        "district": "Brindabella"
    },
    {
        "name": "Wanniassa",
        "district": "Brindabella"
    },
    {
        "name": "Williamsdale",
        "district": "Brindabella"
    },
    {
        "name": "Aranda",
        "district": "Ginninderra"
    },
    {
        "name": "Belconnen",
        "district": "Ginninderra"
    },
    {
        "name": "Belconnen District Nth & Sth",
        "district": "Ginninderra"
    },
    {
        "name": "Bruce",
        "district": "Ginninderra"
    },
    {
        "name": "Charnwood",
        "district": "Ginninderra"
    },
    {
        "name": "Cook",
        "district": "Ginninderra"
    },
    {
        "name": "Dunlop",
        "district": "Ginninderra"
    },
    {
        "name": "Evatt",
        "district": "Ginninderra"
    },
    {
        "name": "Florey",
        "district": "Ginninderra"
    },
    {
        "name": "Flynn",
        "district": "Ginninderra"
    },
    {
        "name": "Fraser",
        "district": "Ginninderra"
    },
    {
        "name": "Hawker",
        "district": "Ginninderra"
    },
    {
        "name": "Higgins",
        "district": "Ginninderra"
    },
    {
        "name": "Holt",
        "district": "Ginninderra"
    },
    {
        "name": "Latham",
        "district": "Ginninderra"
    },
    {
        "name": "Lawson",
        "district": "Ginninderra"
    },
    {
        "name": "Macgregor",
        "district": "Ginninderra"
    },
    {
        "name": "Macquarie",
        "district": "Ginninderra"
    },
    {
        "name": "McKellar",
        "district": "Ginninderra"
    },
    {
        "name": "Melba",
        "district": "Ginninderra"
    },
    {
        "name": "Page",
        "district": "Ginninderra"
    },
    {
        "name": "Scullin",
        "district": "Ginninderra"
    },
    {
        "name": "Spence",
        "district": "Ginninderra"
    },
    {
        "name": "Weetangera",
        "district": "Ginninderra"
    },
    {
        "name": "Acton",
        "district": "Kurrajong"
    },
    {
        "name": "Ainslie",
        "district": "Kurrajong"
    },
    {
        "name": "Barton",
        "district": "Kurrajong"
    },
    {
        "name": "Beard",
        "district": "Kurrajong"
    },
    {
        "name": "Braddon",
        "district": "Kurrajong"
    },
    {
        "name": "Campbell",
        "district": "Kurrajong"
    },
    {
        "name": "Canberra City",
        "district": "Kurrajong"
    },
    {
        "name": "Dickson",
        "district": "Kurrajong"
    },
    {
        "name": "Downer",
        "district": "Kurrajong"
    },
    {
        "name": "Duntroon",
        "district": "Kurrajong"
    },
    {
        "name": "Fairbairn",
        "district": "Kurrajong"
    },
    {
        "name": "Forrest",
        "district": "Kurrajong"
    },
    {
        "name": "Fyshwick",
        "district": "Kurrajong"
    },
    {
        "name": "Griffith",
        "district": "Kurrajong"
    },
    {
        "name": "Hackett",
        "district": "Kurrajong"
    },
    {
        "name": "HMAS Harman",
        "district": "Kurrajong"
    },
    {
        "name": "Hume",
        "district": "Kurrajong"
    },
    {
        "name": "Jerrabomberra District",
        "district": "Kurrajong"
    },
    {
        "name": "Kingston",
        "district": "Kurrajong"
    },
    {
        "name": "Kowen Forest",
        "district": "Kurrajong"
    },
    {
        "name": "Lyneham",
        "district": "Kurrajong"
    },
    {
        "name": "Majura District",
        "district": "Kurrajong"
    },
    {
        "name": "Narrabundah",
        "district": "Kurrajong"
    },
    {
        "name": "O'Connor",
        "district": "Kurrajong"
    },
    {
        "name": "Oaks Estate",
        "district": "Kurrajong"
    },
    {
        "name": "Parkes",
        "district": "Kurrajong"
    },
    {
        "name": "Pialligo",
        "district": "Kurrajong"
    },
    {
        "name": "Red Hill",
        "district": "Kurrajong"
    },
    {
        "name": "Reid",
        "district": "Kurrajong"
    },
    {
        "name": "Russell",
        "district": "Kurrajong"
    },
    {
        "name": "Symonston",
        "district": "Kurrajong"
    },
    {
        "name": "Turner",
        "district": "Kurrajong"
    },
    {
        "name": "Watson",
        "district": "Kurrajong"
    },
    {
        "name": "Chapman",
        "district": "Murrumbidgee"
    },
    {
        "name": "Chifley",
        "district": "Murrumbidgee"
    },
    {
        "name": "Coombs",
        "district": "Murrumbidgee"
    },
    {
        "name": "Coree District",
        "district": "Murrumbidgee"
    },
    {
        "name": "Curtin",
        "district": "Murrumbidgee"
    },
    {
        "name": "Deakin",
        "district": "Murrumbidgee"
    },
    {
        "name": "Denman Prospect",
        "district": "Murrumbidgee"
    },
    {
        "name": "Duffy",
        "district": "Murrumbidgee"
    },
    {
        "name": "Farrer",
        "district": "Murrumbidgee"
    },
    {
        "name": "Fisher",
        "district": "Murrumbidgee"
    },
    {
        "name": "Garran",
        "district": "Murrumbidgee"
    },
    {
        "name": "Holder",
        "district": "Murrumbidgee"
    },
    {
        "name": "Hughes",
        "district": "Murrumbidgee"
    },
    {
        "name": "Isaacs",
        "district": "Murrumbidgee"
    },
    {
        "name": "Kambah (east of Drakeford Dr)",
        "district": "Murrumbidgee"
    },
    {
        "name": "Lyons",
        "district": "Murrumbidgee"
    },
    {
        "name": "Mawson",
        "district": "Murrumbidgee"
    },
    {
        "name": "Molonglo",
        "district": "Murrumbidgee"
    },
    {
        "name": "Mount Stromlo",
        "district": "Murrumbidgee"
    },
    {
        "name": "O'Malley",
        "district": "Murrumbidgee"
    },
    {
        "name": "Pearce",
        "district": "Murrumbidgee"
    },
    {
        "name": "Phillip",
        "district": "Murrumbidgee"
    },
    {
        "name": "Rivett",
        "district": "Murrumbidgee"
    },
    {
        "name": "Stirling",
        "district": "Murrumbidgee"
    },
    {
        "name": "Stromlo",
        "district": "Murrumbidgee"
    },
    {
        "name": "Swinger Hill",
        "district": "Murrumbidgee"
    },
    {
        "name": "Torrens",
        "district": "Murrumbidgee"
    },
    {
        "name": "Tuggeranong District Nth",
        "district": "Murrumbidgee"
    },
    {
        "name": "Uriarra",
        "district": "Murrumbidgee"
    },
    {
        "name": "Waramanga",
        "district": "Murrumbidgee"
    },
    {
        "name": "Weston",
        "district": "Murrumbidgee"
    },
    {
        "name": "Weston Creek",
        "district": "Murrumbidgee"
    },
    {
        "name": "Woden",
        "district": "Murrumbidgee"
    },
    {
        "name": "Wright",
        "district": "Murrumbidgee"
    },
    {
        "name": "Yarralumla",
        "district": "Murrumbidgee"
    },
    {
        "name": "Amaroo",
        "district": "Yerrabi"
    },
    {
        "name": "Bonner",
        "district": "Yerrabi"
    },
    {
        "name": "Casey",
        "district": "Yerrabi"
    },
    {
        "name": "Crace",
        "district": "Yerrabi"
    },
    {
        "name": "Forde",
        "district": "Yerrabi"
    },
    {
        "name": "Franklin",
        "district": "Yerrabi"
    },
    {
        "name": "Giralang",
        "district": "Yerrabi"
    },
    {
        "name": "Gungahlin",
        "district": "Yerrabi"
    },
    {
        "name": "Hall",
        "district": "Yerrabi"
    },
    {
        "name": "Hall District",
        "district": "Yerrabi"
    },
    {
        "name": "Harrison",
        "district": "Yerrabi"
    },
    {
        "name": "Jacka",
        "district": "Yerrabi"
    },
    {
        "name": "Kaleen",
        "district": "Yerrabi"
    },
    {
        "name": "Mitchell",
        "district": "Yerrabi"
    },
    {
        "name": "Moncrieff",
        "district": "Yerrabi"
    },
    {
        "name": "Ngunnawal",
        "district": "Yerrabi"
    },
    {
        "name": "Nicholls",
        "district": "Yerrabi"
    },
    {
        "name": "Palmerston",
        "district": "Yerrabi"
    },
    {
        "name": "Throsby",
        "district": "Yerrabi"
    }
]