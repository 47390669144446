<cdk-accordion>
  <svi-accordion-item title="More info about Smartmap" i18n-title="@@matching.methodologySmartmapTitle">
    <div class="info-panel">
      <p class="hint">
        <ng-container i18n="@@matching.methodologySmartmapDescription">This is a graphical visualisation based on the
          questions (or whatever the method is). Each dimension represents ca. 20 questions. Some questions have been
          assigned to both dimensions and some questions have not been assigned to any. You can find out which questions
          are assigned with which axis on the <a class="methodology-link" (click)="navigateToMethodology()">methodology
            page</a>.
        </ng-container>
      </p>
    </div>
  </svi-accordion-item>
</cdk-accordion>