<svi-navbar backLabel="Back to questionnaire" i18n-backLabel backRoute="/matching"></svi-navbar>
<svi-page-title i18n="@@matching.title">
  Your Individualized Results
</svi-page-title>

<!-- Placeholder-->
<ng-template #hint>
  <svi-tab-group *ngIf="!loading" class="svi-matching-results" [(selectedIndex)]="tabIndex" [isPlaceholder]="true">
    <svi-tab label="Ranking" i18n-label="@@matching.ranking">
      <div>
        <svi-list-item *ngFor="let item of [1,2,3,4]; let i = index" [isPlaceholder]="true" [matchingValue]="0"
          [class.inactive]="true">
        </svi-list-item>
      </div>
    </svi-tab>
    <svi-tab *ngIf="config.hasSmartmap" label="smartmap"> </svi-tab>
  </svi-tab-group>
</ng-template>

<ng-template #resultsActions let-sharingInfo="sharingInfo">
  <div class="actions">
    <button *ngIf="config.showShareButton && tabIndex !== 1 || tabIndex === 0" sviSharingTrigger [sharingInfo]="sharingInfo" class="button d-none d-sm-inline-block"
      (click)="trackSharingTrigger()">
      <i class="fa fa-share-alt"></i>
      <ng-container i18n="@@matching.shareResults">Share Results</ng-container>
    </button>
    <button sviSharingTrigger [sharingInfo]="sharingInfo" class="button button-icon d-inline-block d-sm-none">
      <i class="fa fa-share-alt"></i>
    </button>
  </div>
</ng-template>

<svi-page-content>
  <svi-filter-group [disabled]="loading" [elections]="elections | async" [state]="filterGroupStateChanges | async"
    (stateChanged)="onFilterGroupChanged($event)"></svi-filter-group>
</svi-page-content>

<svi-loading *ngIf="loading"></svi-loading>

<ng-container *ngIf="recommendation | async as recommendation; else hint">
  <ng-container *ngIf="!loading">
    <svi-tab-group class="svi-matching-results" (selectedTabChanged)="onTabChanged($event)"
      [(selectedIndex)]="tabIndex">
      <svi-tab label="Ranking" i18n-label="@@matching.ranking">
        <!-- Sharing Info, set meta
        tags only if this tab is active -->
        <ng-container #sharingInfo="sviSharingInfo" sviSharingInfo [setMetaTags]="tabIndex === 0"
          title="smartvote Australia" i18n-title="@@matching.smartvoteTitle"
          description="Here is my voting advice for the smartvote Australia" i18n-description="matching.myVotingAdvice"
          [imageFromElement]="sharingElement"
          [sharePath]="'/' + localeId + '/share/results?tab=0&rid=' + recommendation.id"></ng-container>

        <ng-container *ngTemplateOutlet="resultsActions; context: {sharingInfo: sharingInfo}"></ng-container>
        <div #sharingElement="sviSharingElement" sviSharingElement>
          <svi-list-item *ngFor="let matching of recommendation.matchings; let i = index"
            [class.lastseat]="config.showDividingLine && recommendation.options.responderType === 'Candidate' && i === nofSeats - 1"
            [class.inactive]="config.showDividingLine && recommendation.options.responderType === 'Candidate' && i > nofSeats - 1"
            [photoUrl]="matching.responder ? matching.responder.photoUrl : null" [rank]="i + 1" [title]="matching.title"
            [description]="matching.description" [matchingValue]="matching.matchingValue"
            (click)="onMatchingSelected(matching)">
          </svi-list-item>
        </div>
      </svi-tab>
      <svi-tab *ngIf="config.hasSmartmap" label="smartmap">
        <!-- Sharing Info, set meta
        tags only if this tab is active -->
        <div class="checkbox-wrapper">
          <svi-custom-checkbox [checked]="showVoterPosition" (valueChanged)="showVoterPosition = $event"
            childId="my-smartmap-position">
            <span i18n="@@matching.mySmartmapPosition">Meine smartmap Position</span>
          </svi-custom-checkbox>
        </div>
        <ng-container #sharingInfo2="sviSharingInfo" sviSharingInfo [setMetaTags]="tabIndex === 1"
          title="smartvote Australia" i18n-title="@@matching.smartvoteTitle"
          description="Here is my smartmap for the smartvote Australia"
          i18n-description="@@matching.smartmapShareDescription" [imageFromElement]="sharingElement2"
          [sharePath]="'/' + localeId + '/share/results?tab=1&rid=' + recommendation.id"></ng-container>
        <ng-container *ngTemplateOutlet="resultsActions; context: {sharingInfo: sharingInfo2}"></ng-container>

        <div class="smartmap">
          <ng-container *ngIf="elections | async as elections;">
            <sv-smartmap #sharingElement2="sviSharingElement" sviSharingElement [screenshotHeight]="560"
              [positions]="recommendation.positions" [labelLeft]="elections[0].dimensions[0].pole1"
              [myPosition]="showVoterPosition ? recommendation.voter.smartmapPosition : null"
              [labelRight]="elections[0].dimensions[0].pole2" [labelTop]="elections[0].dimensions[1].pole2"
              [labelBottom]="elections[0].dimensions[1].pole1" (onSelect)="onSmartmapItemSelect($event)"></sv-smartmap>
          </ng-container>
          <ul class="smartmap-legend">
            <li *ngFor="let item of recommendation.smartmapLegendItems">
              <svi-chart-legend-item [label]="item.label" [color]="item.color"></svi-chart-legend-item>
            </li>
          </ul>
          <svi-smartmap-methodology></svi-smartmap-methodology>
        </div>
      </svi-tab>
    </svi-tab-group>
    <div class="svi-matching-result-summary">
      <div class="item">
        <ng-container i18n>District</ng-container>: {{resultSummary.district}}
      </div>
      <div class="item">
        <ng-container i18n>No. of seats</ng-container>: {{resultSummary.nofSeats}}
      </div>
      <div class="item">
        <ng-container i18n>No. of candidates</ng-container>: {{resultSummary.nofCandidates}}
      </div>
      <!-- <div class="item">
      <ng-container i18n>No. of participating candidates</ng-container>: {{resultSummary.nofParticipatingCandidates}}
    </div> -->
    </div>
  </ng-container>
</ng-container>

<ng-template #translations>
  <span id="no-information" i18n>No information</span>
  <span id="no-answers" i18n>No answers</span>
  <span id="my-position" i18n>My position</span>
</ng-template>