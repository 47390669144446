import { Component } from '@angular/core'

@Component({
  selector: 'svi-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  year: string = new Date().getFullYear().toString()
}
