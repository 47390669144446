import { Component, ViewChild, TemplateRef, AfterViewInit, Inject } from '@angular/core'
import { Election, Candidate, Party } from '@smartvote/common'
import { Apollo } from 'apollo-angular'
import { Observable, BehaviorSubject, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { Router, ActivatedRoute, Params } from '@angular/router'
// const query = require('graphql-tag/loader!./party-details.page.graphql')
import { GetDatabaseElectionsQuery } from '../../__generated__/types'
const { GetDatabaseElections } = require('graphql-tag/loader!./database.page.graphql')
import { FilterGroupState } from './database-filter-group/database-filter-group.component'
import { LocalStorage } from '../core/tokens'

interface CandidateWithDesc extends Candidate {
  description: string
}

@Component({
  selector: 'svi-database',
  templateUrl: 'database.page.html',
  styleUrls: ['database.page.scss']
})
export class DatabasePage implements AfterViewInit {
  elections: Observable<Election[]>
  parties: Party[]
  candidates: Observable<CandidateWithDesc[]>
  filterGroupStateChanges: BehaviorSubject<FilterGroupState> = new BehaviorSubject(
    new FilterGroupState()
  )
  loadingdata = false
  @ViewChild('translations') translationTemplate: TemplateRef<any>
  translations: any
  showList = false
  tabIndex = 0

  constructor(
    private apollo: Apollo,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(LocalStorage) private _localStorage: Storage
  ) {
    this.elections = this.getElections() as Observable<any>
    this.elections.subscribe (elections => {
      this.parties = elections[0].parties
    })
    this.candidates = combineLatest([this.elections, this.filterGroupStateChanges]).pipe(
      map(([elections, filterGroupState]) => {
        this.loadingdata = false
        let election = null
        if (elections.length > 1) {
          election = elections.find((e) => e.id === filterGroupState.election)
        } else {
          election = elections[0]
        }
        return this.filterCandidates(election.candidates, filterGroupState).map(candidate => {
          return {
            ...candidate,
            description: this.getCandidateDescription(candidate)
          }
        })
      })
    ) as any

    this.route.queryParamMap.subscribe(queryParamMap => {
      this.tabIndex = parseInt(queryParamMap.get('tab'), 10) || 0
    })

    const savedState = this._localStorage.getItem('profiles-filter-group')
    if (savedState) {
      this.onSearch(JSON.parse(savedState))
    }
  }

  ngAfterViewInit() {
    this.translations = this.translationTemplate
      .createEmbeddedView({})
      .rootNodes.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.textContent }), {})
  }

  getElections() {
    return this.apollo
      .query<GetDatabaseElectionsQuery>({
        query: GetDatabaseElections
      })
      .pipe(map(({ data }) => data.elections))
  }

  onSearch(state: FilterGroupState) {
    this.loadingdata = true
    this.showList = true
    this._localStorage.setItem('profiles-filter-group', JSON.stringify(state))
    this.filterGroupStateChanges.next(state)
  }

  onReset() {
    this._localStorage.setItem('profiles-filter-group', '')
    this.showList = false
  }

  onTabChanged(index: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: index
      },
      replaceUrl: true,
      queryParamsHandling: 'merge'
    })
  }

  navigateToCandidate(candidateId, electionId) {
    this.router.navigate(['profiles', 'candidate', candidateId, electionId])
  }

  navigateToParty(partyId) {
    this.router.navigate(['profiles', 'party', partyId])
  }

  private filterCandidates(candidates: Candidate[], filterGroupState: FilterGroupState) {
    return candidates.filter(candidate => {
      if (filterGroupState.district) {
        if (!(candidate.district && candidate.district.id === filterGroupState.district)) {
          return false
        }
      }
      if (filterGroupState.party) {
        if (!(candidate.party && candidate.party.id === filterGroupState.party)) {
          return false
        }
      }
      if (filterGroupState.incumbent !== '') {
        if (
          !(
            (candidate.incumbent && filterGroupState.incumbent) ||
            (!candidate.incumbent && !filterGroupState.incumbent)
          )
        ) {
          return false
        }
      }
      if (filterGroupState.elected !== '') {
        if (
          !(
            (candidate.elected && filterGroupState.elected) ||
            (!candidate.elected && !filterGroupState.elected)
          )
        ) {
          return false
        }
      }
      if (filterGroupState.gender) {
        if (!(candidate.gender.toString() === filterGroupState.gender)) {
          return false
        }
      }
      if (filterGroupState.answersConfirmed) {
        if (
          !(
            (candidate.answersConfirmed && filterGroupState.answersConfirmed === 'true') ||
            (!candidate.answersConfirmed && filterGroupState.answersConfirmed === 'false')
          )
        ) {
          return false
        }
      }
      if (filterGroupState.name) {
        if (
          !(
            candidate.firstname.toLowerCase().includes(filterGroupState.name.toLowerCase()) ||
            candidate.lastname.toLowerCase().includes(filterGroupState.name.toLowerCase())
          )
        ) {
          return false
        }
      }
      return true
    })
  }

  private getCandidateDescription(candidate: Candidate) {
    const descriptions = []
    if (candidate.party) {
      descriptions.push(candidate.party.name)
    }
    if (candidate.incumbent) {
      descriptions.push(this.getTranslatedMessage('incumbent'))
    }
    if (candidate.elected) {
      descriptions.push(this.getTranslatedMessage('elected'))
    }
    if (candidate.yearOfBirth) {
      descriptions.push(candidate.yearOfBirth)
    }
    if (candidate.gender) {
      if (candidate.gender === 1) {
        descriptions.push(this.getTranslatedMessage('female'))
      } else if (candidate.gender === 2) {
        descriptions.push(this.getTranslatedMessage('male'))
      } else if (candidate.gender === 3) {
        descriptions.push(this.getTranslatedMessage('other'))
      }
    }
    return descriptions.reduce((previousValue, currentValue) => {
      if (previousValue) {
        return `${previousValue} | ${currentValue}`
      } else {
        return `${currentValue}`
      }
    }, '')
  }

  private getTranslatedMessage(msg: string): string {
    return this.translations[msg]
  }
}
