<div class="social svi-dialog">
  <i class="fab fa-twitter" (click)="sharingService.shareOnTwitter(sharingInfo)"></i>
  <i class="fab fa-facebook-square" (click)="sharingService.shareOnFacebook(sharingInfo)"></i>
  <!-- <i class="fa fa-instagram" (click)="shareOnInstagram()"></i> -->
  <a [href]="sharingService.getWhatsappUrl(sharingInfo)" data-action="share/whatsapp/share">
    <i class="fab fa-whatsapp d-inline d-sm-none"></i>
  </a>
  <a [href]="sharingService.getMailUrl(sharingInfo)">
    <i class="fa fa-envelope"></i>
  </a>
  <div class="tooltip__arrow"></div>
</div>
