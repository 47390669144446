import { Injectable, Inject } from '@angular/core'
import * as uuid from 'uuid'
import { LocalStorage } from './tokens'

@Injectable()
export class VoterIdService {
  constructor(@Inject(LocalStorage) private localStorage: Storage) {
    const voterId = localStorage.getItem('smv-voterId')
    if (!voterId) {
      this.localStorage.setItem('smv-voterId', uuid.v1())
    }
  }

  getVoterId() {
    return this.localStorage.getItem('smv-voterId')
  }
}
