import { Component, ElementRef, ViewChild, Injectable, Injector } from '@angular/core'
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal'
import { NavbarMenuOverlayComponent } from './navbar-menu-overlay/navbar-menu-overlay.component'
import { NavbarMenuOverlayRemote } from './navbar-menu-overlay/navbar-menu-overlay-remote'
import {
  Overlay,
  ConnectedPositionStrategy,
  OverlayConfig,
  ConnectedPosition,
  OverlayRef
} from '@angular/cdk/overlay'

// Define interface for overlay config
interface NavbarMenuConfig {
  hasBackdrop?: boolean
  backdropClass?: string
  panelClass?: string
}
@Injectable()
@Component({
  selector: 'svi-navbar-menu',
  templateUrl: './navbar-menu.component.html',
  styleUrls: ['./navbar-menu.component.scss']
})
export class NavbarMenuComponent {
  constructor(private overlay: Overlay, private injector: Injector) {}

  private overlayRef // Reference to overlay for disposing of it
  @ViewChild('menuButton') menuButton: ElementRef // Reference to menu button for toggling css class

  // Toggle overlay
  clicked() {
    if (this.menuButton.nativeElement.classList.toggle('transform-button')) {
      this.menuButton.nativeElement.classList.toggle('in-front')
      this.overlayRef = this.open()
    } else {
      this.menuButton.nativeElement.classList.toggle('in-front')
      this.overlayRef.dispose()
    }
  }

  // Open an overlay
  open() {
    // Set config and create overlay from config
    const menuConfig: NavbarMenuConfig = {
      hasBackdrop: true,
      backdropClass: 'solid-backdrop',
      panelClass: 'full-size-pane'
    }

    // Create overlay from above config
    const overlayRef = this.createOverlay(menuConfig)

    // Create remote control to close overlay from within
    const remoteControl = new NavbarMenuOverlayRemote(overlayRef, this.menuButton)

    // Set up injectables and create injector
    const injectionToken = new WeakMap()
    injectionToken.set(NavbarMenuOverlayRemote, remoteControl)
    const injector = new PortalInjector(this.injector, injectionToken)

    // Create ComponentPortal and attach it to overlay
    const navbarMenuPortal = new ComponentPortal(NavbarMenuOverlayComponent, null, injector)
    const navbarMenuComponent = overlayRef.attach(navbarMenuPortal).instance

    return overlayRef
  }

  // Create full config from given partial config. Then, create overlay from full config. Then, return.
  private createOverlay(config: NavbarMenuConfig): OverlayRef {
    // Set positionStrategy to create global overlay
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically()

    // Create full config
    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.block() // Set scrollStrategy to block scrolling
    })

    // Create overlay from config and return
    return this.overlay.create(overlayConfig)
  }
}
