<svi-navbar backLabel="Back to questionnaire" i18n-backLabel></svi-navbar>
<svi-page-title i18n="@@userSurvey.title">
  Questions About You
</svi-page-title>
<svi-page-content>

  <div class="grid grid-reverse grid-align-top">
    <div class="cell text-right">
      <button class="button button-primary button-large icon-right" (click)="continue({saveForm: false})">
        <ng-container i18n="@@userSurvey.skipUserSurvey">Continue directly to your results</ng-container>
        <i class="fa fa-chevron-right"></i>
      </button>
    </div>
    <div class="cell">
      <p class="hint ">
        <b i18n="@@userSurvey.dataNote">Please note</b>:
        <ng-container i18n="@@userSurvey.dataHandling">your answers will be treated anonymously and will not affect your
          results</ng-container>
      </p>
    </div>
  </div>
  <form class="svi-questions-about-you-form" [formGroup]="form">
    <div class="section">
      <h3 i18n="@@userSurvey.biographics">Biographical information</h3>
      <div class="grid grid-gutter">
        <div class="field cell">
          <i class="fa fa-home"></i>
          <input type="text" placeholder="Suburb" i18n-placeholder="@@userSurvey.field_1Placeholder"
            [formControl]="form.get('field_1')" class="form-control w-300" [autocomplete]="'any'"
            [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let suburb of filteredSuburbs | async" [value]="suburb">
              {{suburb}}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="field cell">
          <i class="fa fa-birthday-cake"></i>
          <input class="form-control" type="text" maxlength="4" (keypress)="numberOnly($event)"
            placeholder="Year of birth" pattern="[0-9]{4}" i18n-placeholder="@@userSurvey.field_2Placeholder"
            formControlName="field_2">
        </div>
      </div>
      <div class="grid grid-gutter">
        <div class="field cell">
          <div class="icon-group">
            <i class="fa fa-male"></i>
            <i class="fa fa-female"></i>
          </div>
          <select class="form-control" formControlName="drop_1">
            <option value="" i18n="@@userSurvey.drop_1.default">Gender</option>
            <option value="1" i18n="@@userSurvey.drop_1.value_1">Female</option>
            <option value="2" i18n="@@userSurvey.drop_1.value_2">Male</option>
            <option value="3" i18n="@@userSurvey.drop_1.value_3">Other</option>
            <option value="4" i18n="@@userSurvey.drop_1.value_4">NA</option>
          </select>
        </div>
        <div class="field cell">
          <i class="fa fa-university"></i>
          <select class="form-control" formControlName="drop_2">
            <option value="" i18n="@@userSurvey.drop_2.default">Educational qualification</option>
            <option value="1" i18n="@@userSurvey.drop_2.value_1">No qualification since leaving school</option>
            <option value="2" i18n="@@userSurvey.drop_2.value_2">Non-trade qualification</option>
            <option value="3" i18n="@@userSurvey.drop_2.value_3">Trade qualification</option>
            <option value="4" i18n="@@userSurvey.drop_2.value_4">Associate diploma</option>
            <option value="5" i18n="@@userSurvey.drop_2.value_5">Undergraduate diploma</option>
            <option value="6" i18n="@@userSurvey.drop_2.value_6">Bachelor degree (including honours)</option>
            <option value="7" i18n="@@userSurvey.drop_2.value_7">Postgraduate degree/diploma</option>
            <option value="8" i18n="@@userSurvey.drop_2.value_8">Other</option>
            <option value="9" i18n="@@userSurvey.drop_2.value_9">NA</option>
          </select>
        </div>
      </div>
      <div class="grid grid-gutter">
        <div class="field cell">
          <i class="fa fa-language"></i>
          <select class="form-control" formControlName="drop_3">
            <option value="" i18n="@@userSurvey.drop_3.default">Language spoken at home</option>
            <option value="1" i18n="@@userSurvey.drop_3.value_1">English only</option>
            <option value="2" i18n="@@userSurvey.drop_3.value_2">Mandarin</option>
            <option value="3" i18n="@@userSurvey.drop_3.value_3">Arabic</option>
            <option value="4" i18n="@@userSurvey.drop_3.value_4">Cantonese</option>
            <option value="5" i18n="@@userSurvey.drop_3.value_5">Vietnamese</option>
            <option value="6" i18n="@@userSurvey.drop_3.value_6">Italian</option>
            <option value="7" i18n="@@userSurvey.drop_3.value_7">Greek</option>
            <option value="8" i18n="@@userSurvey.drop_3.value_8">Other</option>
            <option value="9" i18n="@@userSurvey.drop_3.value_9">NA</option>
          </select>
        </div>
        <div class="field cell">
          <i class="fa fa-briefcase"></i>
          <select class="form-control" formControlName="drop_4">
            <option value="" i18n="@@userSurvey.drop_4.default">Current employment status</option>
            <option value="1" i18n="@@userSurvey.drop_4.value_1">Full-time employment</option>
            <option value="2" i18n="@@userSurvey.drop_4.value_2">Part-time employment</option>
            <option value="3" i18n="@@userSurvey.drop_4.value_3">Self-employed</option>
            <option value="4" i18n="@@userSurvey.drop_4.value_4">Unemployed</option>
            <option value="5" i18n="@@userSurvey.drop_4.value_5">Retired from employment</option>
            <option value="6" i18n="@@userSurvey.drop_4.value_6">Full-time student</option>
            <option value="7" i18n="@@userSurvey.drop_4.value_7">Caregiving or family responsibilities</option>
            <option value="8" i18n="@@userSurvey.drop_4.value_8">Other</option>
            <option value="9" i18n="@@userSurvey.drop_4.value_9">NA</option>
          </select>
        </div>
      </div>
      <div class="cell">
        <label for="drop_5">Which method will you use to cast your vote at the 2020 election</label>
        <select id="drop_5" class="form-control w-50" formControlName="drop_5">
          <option value="" i18n="@@userSurvey.drop_5_default">Select option</option>
          <option value="1" i18n="@@userSurvey.drop_5.value_1">I will vote in an early voting centre</option>
          <option value="2" i18n="@@userSurvey.drop_5.value_2">I will vote at a polling place on election day</option>
          <option value="3" i18n="@@userSurvey.drop_5.value_3">I will cast a postal vote</option>
          <option value="4" i18n="@@userSurvey.drop_5.value_4">I am not enrolled to vote</option>
          <option value="5" i18n="@@userSurvey.drop_5.value_5">Other</option>
          <option value="6" i18n="@@userSurvey.drop_5.value_6">NA</option>
        </select>
        <div class="field cell">
        </div>
      </div>
    </div>
    <div class="cell">
      <label i18n="@@userSurvey.drop_6Label" for="drop_6">Generally speaking, how interested are you in politics</label>
      <select id="drop_6" class="form-control w-50" formControlName="drop_6">
        <option value="" i18n="@@userSurvey.drop_6.default">Select option</option>
        <option value="1" i18n="@@userSurvey.drop_6.value_1">Not at all interested</option>
        <option value="2" i18n="@@userSurvey.drop_6.value_2">Not very interested</option>
        <option value="3" i18n="@@userSurvey.drop_6.value_3">Somewhat interested</option>
        <option value="4" i18n="@@userSurvey.drop_6.value_4">Very interested</option>
        <option value="5" i18n="@@userSurvey.drop_6.value_5">Other</option>
        <option value="6" i18n="@@userSurvey.drop_6.value_6">NA</option>
      </select>
    </div>
    <div class="cell">
      <div class="section">
        <h3 i18n="@@userSurvey.partyPreference">Party preference</h3>
        <label i18n="@@userSurvey.drop7Label" for="drop_7">To which party did you give your first preference vote at the
          House of Representatives 2019 federal election</label>
        <select id="drop_7" class="form-control w-50" formControlName="drop_7">
          <option value="" i18n="@@userSurvey.drop_7.default">Select party</option>
          <option value="1" i18n="@@userSurvey.drop_7.value_1">Labor</option>
          <option value="2" i18n="@@userSurvey.drop_7.value_2">Liberals</option>
          <option value="3" i18n="@@userSurvey.drop_7.value_3">Greens </option>
          <option value="4" i18n="@@userSurvey.drop_7.value_4">Australian Progressives</option>
          <option value="5" i18n="@@userSurvey.drop_7.value_5">Liberal Democrats</option>
          <option value="6" i18n="@@userSurvey.drop_7.value_6">Sustainable Australia</option>
          <option value="7" i18n="@@userSurvey.drop_7.value_7">United Australia Party</option>
          <option value="8" i18n="@@userSurvey.drop_7.value_8">Independent</option>
          <option value="9" i18n="@@userSurvey.drop_7.value_9">Other</option>
          <option value="10" i18n="@@userSurvey.drop_7.value_10">Do not remember</option>
          <option value="11" i18n="@@userSurvey.drop_7.value_11">NA</option>
        </select>
      </div>
    </div>

    <svi-loading *ngIf="!(parties | async)?.length"></svi-loading>
    <div *ngIf="(parties | async)?.length > 0" class="section">
      <p class="hint">
        <ng-container i18n="@@userSurvey.partyPreferenceIntroduction">How likely is it that you vote for a candidate
          from the following parties?</ng-container>
        <br>
        <ng-container i18n="@@userSurvey.partyPreferenceExplanation">'0' means "not at all likely " and '10' means "very
          likely”.</ng-container>
      </p>
      <div class="grid grid-gutter" *ngFor="let party of parties | async">
        <div class="cell field">
          <label for="party+{{party.id}}">
            {{party.name}}: {{form.controls['party_' + party.id].value}}
            <br>
            <span class="link" [class.invisible]="form.controls['party_' + party.id].pristine"
              (click)="reset('party_' + party.id)" i18n="@@reset">Reset</span>
          </label>
          <input id="party{{party.id}}" type="range" min="0" max="10" step="1" formControlName="party_{{party.id}}">
        </div>
      </div>
      <div class="grid grid-gutter">
        <div class="cell field">
          <label for="other_party">
            <span i18n="@@userSurvey.otherParties">Other parties</span>: {{form.controls['other_party'].value}}
            <br>
            <span class="link" [class.invisible]="form.controls['other_party'].pristine"
              (click)="reset(additionalParty.id)" i18n="@@reset">Reset</span>
          </label>
          <input #additionalParty id="other_party" type="range" min="0" max="10" step="1" formControlName="other_party">
        </div>
      </div>
      <div class="grid grid-gutter" *ngIf="!form.controls['other_party'].pristine">
        <div class="cell field">
          <label for="other_party_name" i18n="@@userSurvey.otherPartiesNameLabel">Please specify other party
          </label>
          <input type="text" class="form-control" id="other_party_name"
            i18n-placeholder="@@userSurvey.otherPartiesNamePlaceholder" placeholder="Other party name"
            formControlName="other_party_name">
        </div>
      </div>
    </div>
    <label #e i18n="@@userSurvey.slider_1Label" for="slider_1">
      In politics, people sometimes talk about the 'left' and the 'right'.
      Where would you place yourself on a scale from '0' to '10'?
      '0' means the left and '10' means the right
    </label>
    <input id="slider_1" type="range" min="0" max="10" step="1" formControlName="slider_1">
    <div class="text-13">{{form.controls['slider_1'].value}}
      <br>
      <span class="link" [class.invisible]="form.controls['slider_1'].pristine" (click)="reset('slider_1')"
        i18n="@@reset">Reset</span>
    </div>
  </form>
  <div class="section">
    <h3 i18n="@@userSurvey.findOutMore">Sign up to find out more about our work</h3>
    <div class="grid grid-gutter">
      <div class="cell">
      <p i18n="@@userSurvey.findOutMoreText">The Australian National University (ANU) undertakes research and outreach
        on the challanges facing democracy. Find out more and subscribe to our mailing list.
      </p>
      </div>
      <div class="cell text-right">
      <a href="https://politicsir.cass.anu.edu.au/research/projects/electoral-surveys/smartvote" target="_blank"
        class="button button-link">
        <i class="fa fa-info-circle"></i>
        <ng-container i18n=@@userSurvey.smartvoteAtAnu>smartvote@ANU</ng-container>
      </a>
      </div>
    </div>
  </div>
  <div class="grid grid-gutter grid-align-bottom section">
    <div class="cell">
      <h3 i18n="@@userSurvey.thankYou">Thank you for participating!</h3>
    </div>
    <div class="cell text-right">
      <button class="button button-primary button-large icon-right" (click)="continue({saveForm: true})">
        <ng-container i18n="@@userSurvey.showResults">See results</ng-container>
        <i class="fa fa-chevron-right"></i>
      </button>
    </div>
  </div>
</svi-page-content>