import { OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer, Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { SharingElementDirective } from './sharing-element.directive';
var SharingInfoDirective = /** @class */ (function () {
    function SharingInfoDirective(meta, platformId, request) {
        this.meta = meta;
        this.platformId = platformId;
        this.request = request;
        this.imagePath = '';
        this.setMetaTags = true;
        this.mailSubject = '';
        this.mailBody = '';
        this.sharePath = '';
    }
    Object.defineProperty(SharingInfoDirective.prototype, "path", {
        /**
         * Platform (browser and server) independent utility to get:
         * - Current URL path & query (ex: /en/machting/results?rid=867c26e0-a520-11e8-8ec8-d781b439cbda)
         * or
         * - `sharePath`
         */
        get: function () {
            if (this.sharePath) {
                return this.sharePath;
            }
            if (isPlatformBrowser(this.platformId)) {
                // browser
                return window.location.pathname + window.location.search;
            }
            else if (this.request) {
                // server (angular universal)
                return Location.stripTrailingSlash(this.request.url);
            }
            else {
                // Cannnot determine path
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SharingInfoDirective.prototype, "origin", {
        /**
         * Platform independent utility to get location `origin`
         * Ex: https://smartvote.ch
         */
        get: function () {
            if (isPlatformBrowser(this.platformId)) {
                return window.location.origin;
            }
            else if (this.request) {
                // Universal rendering
                var host = this.request.headers.host;
                return "https://" + host;
            }
            else {
                // Cannot determine full url
                return '//';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SharingInfoDirective.prototype, "url", {
        get: function () {
            return this.origin + this.path;
        },
        enumerable: true,
        configurable: true
    });
    SharingInfoDirective.prototype.ngOnInit = function () {
        if (this.setMetaTags && isPlatformServer(this.platformId)) {
            this.updateMetaTags();
        }
    };
    SharingInfoDirective.prototype.updateMetaTags = function () {
        var _this = this;
        var tags = [
            { property: 'og:title', content: this.title },
            { property: 'og:description', content: this.description },
            { property: 'og:url', content: this.url },
            { property: 'og:type', content: 'article' },
            { property: 'og:image', content: this.getImageUrl() },
            { property: 'og:image:width', content: "600" },
            { property: 'og:image:height', content: "315" },
            { name: 'twitter:card', content: 'summary_large_image' }
        ];
        tags.forEach(function (tag) { return _this.meta.updateTag(tag); });
    };
    SharingInfoDirective.prototype.getImageUrl = function () {
        var pageGrabberUrl = environment.sharing.pageGrabberUrl;
        if (this.imagePath) {
            return "" + this.origin + this.imagePath;
        }
        else if (this.imageFromElement) {
            var selector = '.' + this.imageFromElement.className;
            return pageGrabberUrl + "?url=" + encodeURIComponent(this.url) + "&selector=" + selector;
        }
        else {
            return pageGrabberUrl + "?url=" + encodeURIComponent(this.url);
        }
    };
    return SharingInfoDirective;
}());
export { SharingInfoDirective };
