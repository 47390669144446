import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'svi-smartmap-methodology',
  templateUrl: './smartmap-methodology.component.html',
  styleUrls: ['./smartmap-methodology.component.scss']
})
export class SmartmapMethodologyComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  navigateToMethodology() {
    this.router.navigate(['/methodology'], { fragment: 'smartmap' })
  }

}
