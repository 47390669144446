/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./tab.component";
var styles_TabComponent = [];
var RenderType_TabComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TabComponent, data: {} });
export { RenderType_TabComponent as RenderType_TabComponent };
function View_TabComponent_1(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_TabComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { content: 0 }), (_l()(), i0.ɵand(0, [[1, 2]], null, 0, null, View_TabComponent_1))], null, null); }
export function View_TabComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-tab", [], null, null, null, View_TabComponent_0, RenderType_TabComponent)), i0.ɵdid(1, 49152, null, 0, i1.TabComponent, [], null, null)], null, null); }
var TabComponentNgFactory = i0.ɵccf("svi-tab", i1.TabComponent, View_TabComponent_Host_0, { label: "label" }, {}, ["*"]);
export { TabComponentNgFactory as TabComponentNgFactory };
