/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./matching-value-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./matching-value-bar.component";
var styles_MatchingValueBarComponent = [i0.styles];
var RenderType_MatchingValueBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MatchingValueBarComponent, data: {} });
export { RenderType_MatchingValueBarComponent as RenderType_MatchingValueBarComponent };
export function View_MatchingValueBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "progress-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "progress-bar"]], [[4, "width", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._value; _ck(_v, 1, 0, currVal_0); }); }
export function View_MatchingValueBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-matching-value-bar", [], null, null, null, View_MatchingValueBarComponent_0, RenderType_MatchingValueBarComponent)), i1.ɵdid(1, 49152, null, 0, i2.MatchingValueBarComponent, [], null, null)], null, null); }
var MatchingValueBarComponentNgFactory = i1.ɵccf("svi-matching-value-bar", i2.MatchingValueBarComponent, View_MatchingValueBarComponent_Host_0, { value: "value" }, {}, []);
export { MatchingValueBarComponentNgFactory as MatchingValueBarComponentNgFactory };
