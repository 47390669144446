import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnDestroy, AfterContentChecked } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TrackingService } from '../../core/tracking.service';
import suburb from '../../../../../shared/assets/js/suburb.js';
import { startWith, map } from 'rxjs/operators';
var FilterGroupState = /** @class */ (function () {
    function FilterGroupState() {
        this.election = '';
        this.district = '';
        this.suburb = '';
        this.responderType = 'Candidate';
    }
    return FilterGroupState;
}());
export { FilterGroupState };
export function stateIsValid(s) {
    return s.election && s.district;
}
export function stateIsEqual(a, b) {
    return (a.election === b.election && a.district === b.district && a.responderType === b.responderType);
}
var FilterGroupComponent = /** @class */ (function () {
    function FilterGroupComponent(fb, trackingService, config) {
        var _this = this;
        this.trackingService = trackingService;
        this.config = config;
        this.focusSet = false;
        this.options = suburb.sort(function (a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        this._disabled = false;
        /* Output `stateChanged` */
        this.stateChanged = new EventEmitter();
        this._state = new FilterGroupState();
        this.form = fb.group(this._state);
        this._sub = this.form.valueChanges.subscribe(function (state) {
            _this._state = tslib_1.__assign({}, _this.state, state);
            _this.stateChanged.emit(_this._state);
        });
    }
    Object.defineProperty(FilterGroupComponent.prototype, "disabled", {
        get: function () {
            return this._disabled;
        },
        set: function (value) {
            this._disabled = value;
            if (value) {
                this.form.disable({ emitEvent: false });
            }
            else {
                this.form.enable({ emitEvent: false });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterGroupComponent.prototype, "elections", {
        get: function () {
            return this._elections;
        },
        /* Input `elections` */
        set: function (value) {
            if (value) {
                this._elections = value;
            }
            else {
                this._elections = [];
            }
            this._setState(new FilterGroupState());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterGroupComponent.prototype, "state", {
        get: function () {
            return this._state;
        },
        /* Input `state` */
        set: function (value) {
            this._setState(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterGroupComponent.prototype, "districts", {
        get: function () {
            if (!this.election) {
                return [];
            }
            return this.election.districts;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterGroupComponent.prototype, "election", {
        get: function () {
            var _this = this;
            if (!this._state.election) {
                return;
            }
            return this.elections.find(function (e) { return e.id === _this.state.election; });
        },
        enumerable: true,
        configurable: true
    });
    FilterGroupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.filteredSuburbs = this.form.get('suburb').valueChanges.pipe(startWith(''), map(function (value) { return _this._filter(value); }));
    };
    FilterGroupComponent.prototype.ngAfterContentChecked = function () {
        if (!this.focusSet) {
            this._focusElement(this._elections);
        }
    };
    FilterGroupComponent.prototype.ngOnDestroy = function () {
        this._sub.unsubscribe();
    };
    FilterGroupComponent.prototype.trackSelectDistrict = function (districtId) {
        this.trackingService.trackEvent('Matching', 'selectDistrict', 'District' + districtId);
    };
    FilterGroupComponent.prototype.handleSelectedSuburb = function (selection) {
        var district = this.election.districts.filter(function (dis) { return dis.name === selection.option.value.district; })[0];
        if (district) {
            this.form.get('district').patchValue(district.id);
        }
    };
    FilterGroupComponent.prototype._setState = function (state) {
        if (state === void 0) { state = new FilterGroupState(); }
        this._state = state;
        if (this.elections.length === 1) {
            this._state.election = this.elections[0].id;
        }
        if (this.elections.length > 1) {
            this.form.controls.election.enable({ emitEvent: false });
        }
        else {
            this.form.controls.election.disable({ emitEvent: false });
        }
        this.form.patchValue(this._state, { emitEvent: false });
    };
    FilterGroupComponent.prototype.displayFn = function (s) {
        if (typeof s === 'string') {
            return s;
        }
        return s.name;
    };
    FilterGroupComponent.prototype._filter = function (value) {
        if (typeof value === 'string') {
            var filterValue_1 = value.toLowerCase();
            return this.options.filter(function (sub) { return sub.name.toLowerCase().includes(filterValue_1); });
        }
    };
    FilterGroupComponent.prototype._focusElement = function (elections) {
        if (elections.length > 1 &&
            this.selectElection &&
            this.selectElection.nativeElement &&
            this.state.election === '') {
            this.selectElection.nativeElement.focus();
            this.focusSet = true;
        }
        else if (elections.length === 1 &&
            this.selectDistrict &&
            this.selectDistrict.nativeElement &&
            this.state.district === '') {
            this.focusSet = true;
            this.selectDistrict.nativeElement.focus();
        }
        else if (this.selectDistrict && this.selectElection) {
            this.selectElection.nativeElement.blur();
            this.selectDistrict.nativeElement.blur();
        }
    };
    return FilterGroupComponent;
}());
export { FilterGroupComponent };
