/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./answer-option-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./answer-option-group.component";
var styles_AnswerOptionGroupComponent = [i0.styles];
var RenderType_AnswerOptionGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AnswerOptionGroupComponent, data: {} });
export { RenderType_AnswerOptionGroupComponent as RenderType_AnswerOptionGroupComponent };
export function View_AnswerOptionGroupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_AnswerOptionGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "svi-answer-option-group", [], null, null, null, View_AnswerOptionGroupComponent_0, RenderType_AnswerOptionGroupComponent)), i1.ɵdid(1, 114688, null, 1, i2.AnswerOptionGroupComponent, [], null, null), i1.ɵqud(603979776, 1, { optionItems: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AnswerOptionGroupComponentNgFactory = i1.ɵccf("svi-answer-option-group", i2.AnswerOptionGroupComponent, View_AnswerOptionGroupComponent_Host_0, {}, {}, ["*"]);
export { AnswerOptionGroupComponentNgFactory as AnswerOptionGroupComponentNgFactory };
