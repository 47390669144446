import { Component } from '@angular/core'

@Component({
  selector: 'svi-page-title',
  template: `
  <div class="svi-page-title">
    <ng-content></ng-content>
  </div>
  `,
  styleUrls: ['page-title.component.scss']
})
export class PageTitleComponent {}
