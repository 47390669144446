<div class="sv-question-standard-actions">
  <button class="button button-large button-primary" (click)="navigate.emit(-1)" [disabled]="isFirstQuestion">
    <i class="fa fa-chevron-left"></i>
    <span class="d-none d-sm-inline" i18n>Back</span>
  </button>
  <button class="button button-large see-results" (click)="done.emit()" [hidden]="doneDisabled || isLastQuestion">{{doneLabel}}</button>
  <button class="button button-large button-primary icon-right" (click)="isLastQuestion ? done.emit() : navigate.emit(1)" [disabled]="doneDisabled && isLastQuestion">
    <span class="d-none d-sm-inline">
      <ng-container *ngIf="!isLastQuestion" i18n>
        Continue
      </ng-container>
      <ng-container *ngIf="isLastQuestion">
        {{doneLabel}}
      </ng-container>
    </span>
    <i class="fa fa-chevron-right"></i>
  </button>
</div>
