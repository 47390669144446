import { SVIVoterConfig } from './voter.configuration'

export const environment: SVIVoterConfig = {
  production: true,
  portierUrl: 'https://uotracx5qg.execute-api.ap-southeast-2.amazonaws.com/production/graphql',
  emailerUrl: 'https://7degjxh594.execute-api.eu-central-1.amazonaws.com/production/send-email',
  emailerFromAddress: 'smartvote.org<noreply@smartvote.org>',
  contactFormToAddress: 'smartvote@anu.edu.au',
  sharing: {
    twitterUsername: 'smartvoteAU',
    pageGrabberUrl: 'http://page-grabber.smartvote.ch/screenshot'
  },
  matomo: {
    url: 'https://analytics.smartvote.ch',
    siteId: 5,
  },
  profileModuleConfiguration: {
    hasSmartspider: true,
    showShareButton: true
  },
  matchingModuleConfiguration: {
    hasSmartmap: true,
    showDividingLine: false,
    electorateHintUrl: 'https://www.elections.act.gov.au/electoral_boundaries/which_electorate_am_i_in',
    showShareButton: false
  },
  questionnaireModuleConfiguration: { showWeight: true },
  questionsAboutYouModuleConfiguration: { showUserSurvey: true },
  showPrivacyLayover: false
}
