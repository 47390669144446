import { Component, OnInit, QueryList, ContentChildren, Input } from '@angular/core'
import { AnswerOptionItemComponent } from './answer-option-item.component'

@Component({
  selector: 'svi-answer-option-group',
  templateUrl: './answer-option-group.component.html',
  styleUrls: ['./answer-option-group.component.scss']
})
export class AnswerOptionGroupComponent implements OnInit {
  @ContentChildren(AnswerOptionItemComponent) optionItems: QueryList<AnswerOptionItemComponent>

  constructor() {}

  ngOnInit() {}
}
