import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  Inject,
  AfterContentChecked
} from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { District, Election } from '@smartvote/common'
import { Subscription, Observable } from 'rxjs'
import { TrackingService } from '../../core/tracking.service'
import { MATCHING_MODULE_CONFIG } from '../matching.module'
import { MatchingModuleConfiguration } from '../matching.module.configuration'
import suburb from '../../../../../shared/assets/js/suburb.js'
import { MatOption } from '@angular/material/core'
import { startWith, map } from 'rxjs/operators';

export class FilterGroupState {
  election = ''
  district = ''
  suburb: { name: string, district: string } | string = ''
  responderType: 'Candidate' | 'Party' = 'Candidate'
}

export function stateIsValid(s) {
  return s.election && s.district
}

export function stateIsEqual(a: FilterGroupState, b: FilterGroupState) {
  return (
    a.election === b.election && a.district === b.district && a.responderType === b.responderType
  )
}

@Component({
  selector: 'svi-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss']
})
export class FilterGroupComponent implements OnDestroy, AfterContentChecked {
  @ViewChild('selectElection') private selectElection: ElementRef
  @ViewChild('selectDistrict') private selectDistrict: ElementRef
  focusSet = false
  options: { name: string, district: string }[] = suburb.sort((a, b) => {
    if (a.name < b.name) { return -1 }
    if (a.name > b.name) { return 1 }
    return 0
  });
  filteredSuburbs: Observable<{ name: string, district: string }[]>;

  @Input()
  set disabled(value: boolean) {
    this._disabled = value
    if (value) {
      this.form.disable({ emitEvent: false })
    } else {
      this.form.enable({ emitEvent: false })
    }
  }
  get disabled() {
    return this._disabled
  }
  private _disabled = false
  /* Input `elections` */
  @Input()
  set elections(value: Election[]) {
    if (value) {
      this._elections = value
    } else {
      this._elections = []
    }
    this._setState(new FilterGroupState())
  }
  get elections(): Election[] {
    return this._elections
  }

  /* Input `state` */
  @Input()
  set state(value: FilterGroupState) {
    this._setState(value)
  }
  get state() {
    return this._state
  }
  /* Output `stateChanged` */
  @Output() stateChanged = new EventEmitter<FilterGroupState>()

  get districts(): District[] {
    if (!this.election) {
      return []
    }
    return this.election.districts
  }
  get election(): Election | undefined {
    if (!this._state.election) {
      return
    }
    return this.elections.find(e => e.id === this.state.election)
  }
  form: FormGroup
  private _elections: Election[]
  private _state: FilterGroupState
  private _sub: Subscription


  constructor(
    fb: FormBuilder,
    private trackingService: TrackingService,
    @Inject(MATCHING_MODULE_CONFIG) readonly config: MatchingModuleConfiguration
  ) {
    this._state = new FilterGroupState()
    this.form = fb.group(this._state)
    this._sub = this.form.valueChanges.subscribe(state => {
      this._state = { ...this.state, ...state }
      this.stateChanged.emit(this._state)
    })
  }

  ngOnInit() {
    this.filteredSuburbs = this.form.get('suburb').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  ngAfterContentChecked() {
    if (!this.focusSet) {
      this._focusElement(this._elections)
    }
  }

  ngOnDestroy() {
    this._sub.unsubscribe()
  }

  trackSelectDistrict(districtId: string) {
    this.trackingService.trackEvent('Matching', 'selectDistrict', 'District' + districtId)
  }

  handleSelectedSuburb(selection: { selected: any, option: MatOption }) {
    const district = this.election.districts.filter(dis => dis.name === selection.option.value.district)[0]
    if (district) {
      this.form.get('district').patchValue(district.id);
    }
  }

  private _setState(state: FilterGroupState = new FilterGroupState()) {
    this._state = state
    if (this.elections.length === 1) {
      this._state.election = this.elections[0].id
    }
    if (this.elections.length > 1) {
      this.form.controls.election.enable({ emitEvent: false })
    } else {
      this.form.controls.election.disable({ emitEvent: false })
    }
    this.form.patchValue(this._state, { emitEvent: false })
  }

  displayFn(s: { name: string, district: string } | string): string {
    if (typeof s === 'string') {
      return s;
    }
    return s.name;
  }

  private _filter(value: string): { name: string, district: string }[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.options.filter(sub => sub.name.toLowerCase().includes(filterValue));

    }
  }

  private _focusElement(elections) {
    if (
      elections.length > 1 &&
      this.selectElection &&
      this.selectElection.nativeElement &&
      this.state.election === '') {
      this.selectElection.nativeElement.focus()
      this.focusSet = true
    } else if (
      elections.length === 1 &&
      this.selectDistrict &&
      this.selectDistrict.nativeElement &&
      this.state.district === ''
    ) {
      this.focusSet = true
      this.selectDistrict.nativeElement.focus()
    } else if (this.selectDistrict && this.selectElection) {
      this.selectElection.nativeElement.blur()
      this.selectDistrict.nativeElement.blur()
    }
  }
}
