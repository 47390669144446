import { Component, Inject, PLATFORM_ID, AfterViewInit } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'svi-partners',
  templateUrl: './partners.page.html',
  styleUrls: ['./partners.page.scss']
})
export class PartnersPage implements AfterViewInit {
  constructor(@Inject(PLATFORM_ID) private platformId) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0)
    }
  }
}
