/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tab-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tab-group.component";
var styles_TabGroupComponent = [i0.styles];
var RenderType_TabGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabGroupComponent, data: {} });
export { RenderType_TabGroupComponent as RenderType_TabGroupComponent };
function View_TabGroupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "tab-label"]], [[2, "active", null], [2, "greyed-out", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.index === _co.selectedIndex); var currVal_1 = _co.isPlaceholder; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_2); }); }
function View_TabGroupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TabGroupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "svi-tab-content"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabGroupComponent_3)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.content; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.index === _co.selectedIndex); _ck(_v, 0, 0, currVal_0); }); }
export function View_TabGroupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "svi-tab-group-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabGroupComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabGroupComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._tabs; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co._tabs; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TabGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "svi-tab-group", [], null, null, null, View_TabGroupComponent_0, RenderType_TabGroupComponent)), i1.ɵdid(1, 114688, null, 1, i3.TabGroupComponent, [], null, null), i1.ɵqud(603979776, 1, { _tabs: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabGroupComponentNgFactory = i1.ɵccf("svi-tab-group", i3.TabGroupComponent, View_TabGroupComponent_Host_0, { selectedIndex: "selectedIndex", isPlaceholder: "isPlaceholder" }, { selectedTabChanged: "selectedTabChanged" }, []);
export { TabGroupComponentNgFactory as TabGroupComponentNgFactory };
