import { Directive, Input, OnInit, Inject, Optional, PLATFORM_ID } from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { isPlatformBrowser, isPlatformServer, Location } from '@angular/common'
import { environment } from '../../../environments/environment'
import { SharingElementDirective } from './sharing-element.directive'
import { REQUEST } from '@nguniversal/express-engine/tokens'

@Directive({
  selector: '[sviSharingInfo]',
  exportAs: 'sviSharingInfo'
})
export class SharingInfoDirective implements OnInit {
  @Input()
  title
  @Input()
  description
  @Input()
  imageFromElement: SharingElementDirective
  @Input()
  imagePath = ''
  @Input()
  setMetaTags = true
  @Input()
  mailSubject = ''
  @Input()
  mailBody = ''
  @Input()
  sharePath = ''

  /**
   * Platform (browser and server) independent utility to get:
   * - Current URL path & query (ex: /en/machting/results?rid=867c26e0-a520-11e8-8ec8-d781b439cbda)
   * or
   * - `sharePath`
   */
  get path() {
    if (this.sharePath) {
      return this.sharePath
    }
    if (isPlatformBrowser(this.platformId)) {
      // browser
      return window.location.pathname + window.location.search
    } else if (this.request) {
      // server (angular universal)
      return Location.stripTrailingSlash(this.request.url)
    } else {
      // Cannnot determine path
      return ''
    }
  }

  /**
   * Platform independent utility to get location `origin`
   * Ex: https://smartvote.ch
   */
  get origin() {
    if (isPlatformBrowser(this.platformId)) {
      return window.location.origin
    } else if (this.request) {
      // Universal rendering
      const { host } = this.request.headers as any
      return `https://${host}`
    } else {
      // Cannot determine full url
      return '//'
    }
  }

  get url() {
    return this.origin + this.path
  }

  constructor(
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional()
    @Inject(REQUEST)
    private request: Request
  ) {}

  ngOnInit() {
    if (this.setMetaTags && isPlatformServer(this.platformId)) {
      this.updateMetaTags()
    }
  }

  private updateMetaTags() {
    const tags = [
      { property: 'og:title', content: this.title },
      { property: 'og:description', content: this.description },
      { property: 'og:url', content: this.url },
      { property: 'og:type', content: 'article' },
      { property: 'og:image', content: this.getImageUrl() },
      { property: 'og:image:width', content: `600` },
      { property: 'og:image:height', content: `315` },
      { name: 'twitter:card', content: 'summary_large_image' }
    ]
    tags.forEach(tag => this.meta.updateTag(tag))
  }

  private getImageUrl() {
    const { pageGrabberUrl } = environment.sharing
    if (this.imagePath) {
      return `${this.origin}${this.imagePath}`
    } else if (this.imageFromElement) {
      const selector = '.' + this.imageFromElement.className
      return `${pageGrabberUrl}?url=${encodeURIComponent(this.url)}&selector=${selector}`
    } else {
      return `${pageGrabberUrl}?url=${encodeURIComponent(this.url)}`
    }
  }
}
