/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../profile/profile-with-voter.component.ngfactory";
import * as i2 from "../profile/profile-with-voter.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/router";
import * as i5 from "../profile/profile.module";
import * as i6 from "../profile/profile.component.ngfactory";
import * as i7 from "../profile/profile.component";
import * as i8 from "../shared/page-content/page-content.component.ngfactory";
import * as i9 from "../shared/page-content/page-content.component";
import * as i10 from "@angular/common";
import * as i11 from "../shared/loading/loading.component.ngfactory";
import * as i12 from "../shared/loading/loading.component";
import * as i13 from "../shared/navbar/navbar.component.ngfactory";
import * as i14 from "../shared/navbar/navbar.component";
import * as i15 from "../core/tokens";
import * as i16 from "../shared/page-title/page-title.component.ngfactory";
import * as i17 from "../shared/page-title/page-title.component";
import * as i18 from "./party-details-share.page";
import * as i19 from "../core/voter-id.service";
import * as i20 from "apollo-angular";
var styles_PartyDetailsSharePage = [];
var RenderType_PartyDetailsSharePage = i0.ɵcrt({ encapsulation: 2, styles: styles_PartyDetailsSharePage, data: {} });
export { RenderType_PartyDetailsSharePage as RenderType_PartyDetailsSharePage };
function View_PartyDetailsSharePage_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-profile-with-voter", [], null, [[null, "selectedTabChanged"], [null, "tabIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChanged" === en)) {
        var pd_0 = (_co.onTabChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("tabIndexChange" === en)) {
        var pd_1 = ((_co.tabIndex = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ProfileWithVoterComponent_0, i1.RenderType_ProfileWithVoterComponent)), i0.ɵdid(1, 638976, null, 0, i2.ProfileWithVoterComponent, [i3.DomSanitizer, i4.Router, i5.PROFILE_MODULE_CONFIG, i0.LOCALE_ID], { tabIndex: [0, "tabIndex"], voter: [1, "voter"], questions: [2, "questions"], responder: [3, "responder"] }, { selectedTabChanged: "selectedTabChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabIndex; var currVal_1 = _v.parent.context.ngIf.voter; var currVal_2 = _v.parent.context.ngIf.questions; var currVal_3 = _v.parent.context.ngIf.party; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_PartyDetailsSharePage_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-profile", [], null, [[null, "selectedTabChanged"], [null, "tabIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChanged" === en)) {
        var pd_0 = (_co.onTabChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("tabIndexChange" === en)) {
        var pd_1 = ((_co.tabIndex = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ProfileComponent_0, i6.RenderType_ProfileComponent)), i0.ɵdid(1, 638976, null, 0, i7.ProfileComponent, [i5.PROFILE_MODULE_CONFIG, i3.DomSanitizer, i4.Router, i0.LOCALE_ID], { tabIndex: [0, "tabIndex"], voter: [1, "voter"], questions: [2, "questions"], responder: [3, "responder"] }, { selectedTabChanged: "selectedTabChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabIndex; var currVal_1 = _v.parent.context.ngIf.voter; var currVal_2 = _v.parent.context.ngIf.questions; var currVal_3 = _v.parent.context.ngIf.party; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_PartyDetailsSharePage_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "svi-page-content", [], null, null, null, i8.View_PageContentComponent_0, i8.RenderType_PageContentComponent)), i0.ɵdid(1, 49152, null, 0, i9.PageContentComponent, [], null, null), (_l()(), i0.ɵted(-1, 0, ["Profile not available"]))], null, null); }
function View_PartyDetailsSharePage_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PartyDetailsSharePage_2)), i0.ɵdid(2, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PartyDetailsSharePage_3)), i0.ɵdid(4, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PartyDetailsSharePage_4)), i0.ɵdid(6, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.ngIf.party && _v.context.ngIf.voter); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.ngIf.party && !_v.context.ngIf.voter); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_v.context.ngIf.party; _ck(_v, 6, 0, currVal_2); }, null); }
function View_PartyDetailsSharePage_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "svi-page-content", [], null, null, null, i8.View_PageContentComponent_0, i8.RenderType_PageContentComponent)), i0.ɵdid(1, 49152, null, 0, i9.PageContentComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "svi-loading", [], null, null, null, i11.View_LoadingComponent_0, i11.RenderType_LoadingComponent)), i0.ɵdid(3, 49152, null, 0, i12.LoadingComponent, [], null, null)], null, null); }
export function View_PartyDetailsSharePage_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-navbar", [["backLabel", "Get your own matching"], ["backRoute", "/home"]], null, null, null, i13.View_NavbarComponent_0, i13.RenderType_NavbarComponent)), i0.ɵdid(1, 49152, null, 0, i14.NavbarComponent, [i10.Location, i4.Router, i15.LocalStorage], { backLabel: [0, "backLabel"], backRoute: [1, "backRoute"], hideMenu: [2, "hideMenu"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "svi-page-title", [], null, null, null, i16.View_PageTitleComponent_0, i16.RenderType_PageTitleComponent)), i0.ɵdid(3, 49152, [["title", 4]], 0, i17.PageTitleComponent, [], null, null), (_l()(), i0.ɵted(-1, 0, ["Profile"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_PartyDetailsSharePage_1)), i0.ɵdid(6, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i10.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["loading", 2]], null, 0, null, View_PartyDetailsSharePage_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Get your own matching"; var currVal_1 = "/home"; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.data)); var currVal_4 = i0.ɵnov(_v, 8); _ck(_v, 6, 0, currVal_3, currVal_4); }, null); }
export function View_PartyDetailsSharePage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-party-details-share", [], null, null, null, View_PartyDetailsSharePage_0, RenderType_PartyDetailsSharePage)), i0.ɵdid(1, 4243456, null, 0, i18.PartyDetailsSharePage, [i4.ActivatedRoute, i4.Router, i19.VoterIdService, i20.Apollo, i0.PLATFORM_ID], null, null)], null, null); }
var PartyDetailsSharePageNgFactory = i0.ɵccf("svi-party-details-share", i18.PartyDetailsSharePage, View_PartyDetailsSharePage_Host_0, {}, {}, []);
export { PartyDetailsSharePageNgFactory as PartyDetailsSharePageNgFactory };
