import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  AfterViewInit,
  HostBinding,
  OnDestroy
} from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { Renderer3 } from '@angular/core/src/render3/interfaces/renderer'
import { SharingInfoDirective } from './sharing-info.directive'

export const SCREENSHOT_ELEMENT_CLASS = 'svi-sharing-screenshot-element'
export const SCREENSHOT_ELEMENT_STYLES = [
  ['display', 'block'],
  ['position', 'absolute'],
  ['z-index', '-1'],
  ['top', '0'],
  ['left', '50%']
]
export const SCREENSHOT_RATIO = 600 / 315

@Directive({
  selector: '[sviSharingElement]',
  exportAs: 'sviSharingElement'
})
export class SharingElementDirective implements AfterViewInit, OnDestroy {
  static INSTANCE_ID = 0
  @Input() screenshotHeight = 315
  @Input() name: string
  screenshotWidth: number
  className: string

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.className = `${SCREENSHOT_ELEMENT_CLASS}-${SharingElementDirective.INSTANCE_ID++}`
  }

  ngOnDestroy() {
    SharingElementDirective.INSTANCE_ID--
  }

  ngAfterViewInit() {
    /**
     * Given the height, compute the width to respect aspect ratio
     * see (https://developers.facebook.com/docs/sharing/best-practices/)
     */
    this.screenshotWidth = this.screenshotHeight * SCREENSHOT_RATIO
    this.addElement()
  }

  // add screenshot element to dom
  addElement() {
    this.renderer.setStyle(this.el.nativeElement, 'position', 'relative')
    this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden')
    this.renderer.setStyle(this.el.nativeElement, 'display', 'block')
    const el = this.renderer.createElement('div')
    this.renderer.addClass(el, this.className)
    SCREENSHOT_ELEMENT_STYLES.forEach(style => this.renderer.setStyle(el, style[0], style[1]))
    this.renderer.setStyle(el, 'width', `${this.screenshotWidth}px`)
    this.renderer.setStyle(el, 'height', `${this.screenshotHeight}px`)
    this.renderer.setStyle(el, 'margin-left', `-${this.screenshotWidth / 2}px`)
    this.renderer.appendChild(this.el.nativeElement, el)
  }
}
