<div class="svi-layout">
  <svi-header></svi-header>
  <div class="content">
    <router-outlet></router-outlet>
    <div class="sponsors">
      <div class="content partner-logo__container">
        <div class="partner-logo__wrapper">
          <a href="https://www.anu.edu.au" target="_blank" class="partner-logo anu"></a>
        </div>
        <div class="partner-logo__wrapper">
          <a href="https://www.canberratimes.com.au" target="_blank" class="partner-logo canberra-times"></a>
        </div>
      </div>
    </div>
  </div>
</div>
<svi-footer></svi-footer>