import { Component, OnInit, Input, Inject } from '@angular/core'
import { Location } from '@angular/common'
import { Router } from '@angular/router'
import { LocalStorage } from '../../core/tokens'

@Component({
  selector: 'svi-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Input()
  backLabel: string
  @Input()
  backRoute: string
  @Input()
  backRouteParam
  @Input()
  hideMenu

  constructor(
    private _location: Location,
    private _router: Router,
    @Inject(LocalStorage) private _localStorage: Storage
  ) {}

  goToMatching() {
    const recommendationId = this._localStorage.getItem('recommendationId')
    if (recommendationId) {
      this._router.navigate(['matching', 'results'], { queryParams: { rid: recommendationId } })
    } else {
      this._router.navigate(['matching'])
    }
  }

  onBack() {
    if (this.backRoute) {
      if (this.backRouteParam) {
        this._router.navigate([this.backRoute], { queryParams: this.backRouteParam })
      } else {
        this._router.navigate([this.backRoute])
      }
    } else {
      this._location.back()
    }
  }
}
