<svi-navbar></svi-navbar>
<svi-page-title i18n="@@partners.title">
  Partners
</svi-page-title>
<svi-page-content>
  <section>
    <h3 i18n="@@partners.mediaPartners">Media partner</h3>
    <div class="partner">
      <a href="https://www.canberratimes.com.au/" target="_blank" class="logo canberra-times"></a>
    </div>
  </section>
</svi-page-content>