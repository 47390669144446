<div class="svi-matching-filter-group">
  <form [formGroup]="form">
    <select #selectElection *ngIf="elections.length > 1" class="form-control" formControlName="election">
      <option value="" i18n="@@filter.selectElection">Select Election</option>
      <option *ngFor="let election of elections" [value]="election.id">{{election.name}}</option>
    </select>
    <ng-container *ngIf="elections && districts[0]">
      <p i18n="@@matching.filterSuburbTitle"><strong>Do you need help finding your electorate? Enter your suburb name
          first below</strong></p>
      <input type="text" placeholder="Enter suburb name (optional)" i18n-placeholder="@@matching.filterSuburbs"
        [formControl]="form.get('suburb')" class="form-control w-300" [autocomplete]="'any'" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
        (optionSelected)="handleSelectedSuburb($event)">
        <mat-option *ngFor="let street of filteredSuburbs | async" [value]="street">
          {{street.name}}
        </mat-option>
      </mat-autocomplete>
    </ng-container>
    <select *ngIf="districts[0]" #selectDistrict class="form-control" formControlName="district"
      (change)="trackSelectDistrict($event.target.value)">
      <option value="" i18n="@@filter.selectDistrict">Select District</option>
      <option *ngFor="let district of districts" [value]="district.id">{{district.name}}</option>
    </select>
    <span *ngIf="config.electorateHintUrl && districts[0]" i18n="@@matching.electorateHint" class="electorate-hint">
      Still can’t find your electorate? Go to the
      <a class="electorate-hint electorate-link" [href]="config.electorateHintUrl" target="_blank">
        Destination
      </a>
    </span>
  </form>
  <div class="grid grid-gutter">
    <div class="cell">
      <p class="font-13" i18n="@@matching.findOutMoreText">The Australian National University (ANU) undertakes research and outreach
        on the challanges facing democracy. Find out more and subscribe to our mailing list.
      </p>
    </div>
    <div id="sign-up" class="cell text-right">
      <a href="https://politicsir.cass.anu.edu.au/research/projects/electoral-surveys/smartvote" target="_blank"
        class="button button-link font-13">
        <i class="fa fa-info-circle"></i>
        <ng-container i18n=@@matching.smartvoteAtAnu>smartvote@ANU</ng-container>
      </a>
    </div>
  </div>
</div>