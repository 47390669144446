<div class="text">
  <div class="question-item">
    <div class="text">
      {{index + 1}}. {{text}}
    </div>
    <div class="comment-link" *ngIf="comment" (click)="toggle()">
      <i class="fa fa-comment"></i>
    </div>
  </div>
  <div class="comment" [class.expanded]="expanded">
    {{comment}}
  </div>
</div>
<ng-content></ng-content>