import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { SmartmapModule } from '@smartvote/components'
import { SharedModule } from '../shared/shared.module'
import { FilterGroupComponent } from './filter-group/filter-group.component'
import { MatchingModuleConfiguration } from './matching.module.configuration'
import { MatchingSharePage } from './matching-share.page'
import { MatchingPage } from './matching.page'
import { CdkAccordionModule } from '@angular/cdk/accordion'
import { SmartmapMethodologyComponent } from './smartmap-methodology/smartmap-methodology.component'
import { MatAutocompleteModule } from '@angular/material/autocomplete'

export const MATCHING_MODULE_CONFIG = new InjectionToken<MatchingModuleConfiguration>(
  'MatchingModuleConfiguration'
)

const COMPONENTS = [
  MatchingPage,
  MatchingSharePage,
  FilterGroupComponent,
  SmartmapMethodologyComponent
]

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CdkAccordionModule,
    SmartmapModule,
    RouterModule,
    MatAutocompleteModule
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class MatchingModule {
  public static forRoot(config: MatchingModuleConfiguration): ModuleWithProviders {
    return {
      ngModule: MatchingModule,
      providers: [{ provide: MATCHING_MODULE_CONFIG, useValue: config }]
    }
  }
}
