import { NgModule, InjectionToken, ModuleWithProviders } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { ProfileCandidateDetailsComponent } from './profile-candidate-details.component'
import { ProfileComponent } from './profile.component'
import { ProfileWithVoterComponent } from './profile-with-voter.component'
import { SharedModule } from '../shared/shared.module'
import { SmartspiderModule } from '@smartvote/components'
import { CdkAccordionModule } from '@angular/cdk/accordion'
import { FormsModule } from '@angular/forms'
import { QuestionItemComponent } from './question-item.component'
import { AnswerOptionItemComponent } from './answer-option-item.component'
import { AnswerOptionGroupComponent } from './answer-option-group.component'
import { ProfileModuleConfiguration } from './profile.module.configuration'
import { SmartspiderMethodologyComponent } from './smartspider-methodology/smartspider-methodology.component'

const COMPONENTS = [
  ProfileComponent,
  ProfileWithVoterComponent,
  ProfileCandidateDetailsComponent,
  QuestionItemComponent,
  AnswerOptionItemComponent,
  AnswerOptionGroupComponent,
  SmartspiderMethodologyComponent
]

export const PROFILE_MODULE_CONFIG = new InjectionToken<ProfileModuleConfiguration>(
  'ProfileModuleConfiguration'
)

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SmartspiderModule,
    CdkAccordionModule,
    RouterModule,
    FormsModule
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ProfileModule {
  public static forRoot(config: ProfileModuleConfiguration): ModuleWithProviders {
    return {
      ngModule: ProfileModule,
      providers: [{ provide: PROFILE_MODULE_CONFIG, useValue: config }]
    }
  }
}
