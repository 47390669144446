/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./navbar-menu.component";
import * as i3 from "@angular/cdk/overlay";
var styles_NavbarMenuComponent = [i0.styles];
var RenderType_NavbarMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarMenuComponent, data: {} });
export { RenderType_NavbarMenuComponent as RenderType_NavbarMenuComponent };
export function View_NavbarMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { menuButton: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "svi-navbar-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "spacer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["menuButton", 1]], null, 3, "div", [["class", "bar-menu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "top-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "middle-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "bottom-bar"]], null, null, null, null, null))], null, null); }
export function View_NavbarMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-navbar-menu", [], null, null, null, View_NavbarMenuComponent_0, RenderType_NavbarMenuComponent)), i1.ɵdid(1, 49152, null, 0, i2.NavbarMenuComponent, [i3.Overlay, i1.Injector], null, null)], null, null); }
var NavbarMenuComponentNgFactory = i1.ɵccf("svi-navbar-menu", i2.NavbarMenuComponent, View_NavbarMenuComponent_Host_0, {}, {}, []);
export { NavbarMenuComponentNgFactory as NavbarMenuComponentNgFactory };
