/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-checkbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./custom-checkbox.component";
var styles_CustomCheckboxComponent = [i0.styles];
var RenderType_CustomCheckboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomCheckboxComponent, data: {} });
export { RenderType_CustomCheckboxComponent as RenderType_CustomCheckboxComponent };
export function View_CustomCheckboxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "label", [["class", "checkbox-container"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0], [8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.changed($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "checkmark"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.childId, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.checked; var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.childId, ""); _ck(_v, 1, 0, currVal_1, currVal_2); }); }
export function View_CustomCheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-custom-checkbox", [], null, null, null, View_CustomCheckboxComponent_0, RenderType_CustomCheckboxComponent)), i1.ɵdid(1, 49152, null, 0, i2.CustomCheckboxComponent, [], null, null)], null, null); }
var CustomCheckboxComponentNgFactory = i1.ɵccf("svi-custom-checkbox", i2.CustomCheckboxComponent, View_CustomCheckboxComponent_Host_0, { childId: "childId", checked: "checked" }, { valueChanged: "valueChanged" }, ["*"]);
export { CustomCheckboxComponentNgFactory as CustomCheckboxComponentNgFactory };
