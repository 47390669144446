import { Component, Inject } from '@angular/core'
import { Router } from '@angular/router'
import { PROFILE_MODULE_CONFIG } from '../profile.module'
import { ProfileModuleConfiguration } from '../profile.module.configuration'

@Component({
  selector: 'svi-smartspider-methodology',
  templateUrl: './smartspider-methodology.component.html',
  styleUrls: ['./smartspider-methodology.component.scss']
})
export class SmartspiderMethodologyComponent {
  constructor(private readonly router: Router, @Inject(PROFILE_MODULE_CONFIG) readonly config: ProfileModuleConfiguration,
  ) {}

  navigateToMethodology() {
    this.router.navigate(['/methodology'], { fragment: 'smartspider' })
  }
}
