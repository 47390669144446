import { Component, Inject, PLATFORM_ID, AfterViewInit } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'
import { TrackingService } from '../../core/tracking.service'

@Component({
  selector: 'svi-terms-and-privacy',
  templateUrl: './terms-and-privacy.page.html',
  styleUrls: ['./terms-and-privacy.page.scss']
})
export class TermsAndPrivacyPage implements AfterViewInit {
  constructor(@Inject(PLATFORM_ID) private platformId, private trackingService: TrackingService) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0)
    }
  }

  trackViewTerms() {
    this.trackingService.trackEvent('Common', 'viewTermsAndPrivacy')
  }
}
