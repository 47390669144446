<svi-navbar backLabel="Get your own matching" i18n-backLabel [hideMenu]="true" backRoute="/home"></svi-navbar>
<svi-page-title #title i18n>Profile</svi-page-title>
<ng-container *ngIf="data | async as data; else loading">
  <svi-profile-with-voter *ngIf="data.party && data.voter" [questions]="data.questions" [voter]="data.voter" [responder]="data.party"
    (selectedTabChanged)="onTabChanged($event)" [(tabIndex)]="tabIndex">
  </svi-profile-with-voter>
  <svi-profile *ngIf="data.party && !data.voter" [questions]="data.questions" [voter]="data.voter" [responder]="data.party"
    (selectedTabChanged)="onTabChanged($event)" [(tabIndex)]="tabIndex">
  </svi-profile>
  <svi-page-content *ngIf="!data.party" i18n>Profile not available</svi-page-content>
</ng-container>

<ng-template #loading>
  <svi-page-content>
    <svi-loading></svi-loading>
  </svi-page-content>
</ng-template>