<div class="svi-question-container">
  <div class="category">
    {{question.category.name}}
  </div>
  <div class="text" #questionText>
    {{question.text}}
  </div>
  <div *ngIf="!question.infoText" class="more-info-placeholder"></div>
  <cdk-accordion *ngIf="question.infoText">
    <svi-accordion-item class="title-right" title="More info" i18n-title [expanded]="infoTextExpanded">
      <div class="info-panel">
        {{question.infoText}}
      </div>
    </svi-accordion-item>
  </cdk-accordion>
</div>