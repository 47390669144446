import { Component, Input } from '@angular/core'

@Component({
  selector: 'svi-matching-value-bar',
  template: `
  <div class="progress-background">
    <div class="progress-bar" [style.width]="_value">
    </div>
  </div>
  `,
  styleUrls: ['./matching-value-bar.component.scss']
})
export class MatchingValueBarComponent {
  @Input()
  set value(value: number) {
    this._value = value + '%'
  }
  public _value: string

  constructor() {}
}
