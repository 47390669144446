var MatchingValueBarComponent = /** @class */ (function () {
    function MatchingValueBarComponent() {
    }
    Object.defineProperty(MatchingValueBarComponent.prototype, "value", {
        set: function (value) {
            this._value = value + '%';
        },
        enumerable: true,
        configurable: true
    });
    return MatchingValueBarComponent;
}());
export { MatchingValueBarComponent };
