import { Component } from '@angular/core'

@Component({
  selector: 'svi-page-content',
  template: `
  <div class="svi-page-content">
    <ng-content></ng-content>
  </div>
  `,
  styleUrls: ['page-content.component.scss']
})
export class PageContentComponent {}
