import { Component, Input } from '@angular/core'

@Component({
  selector: 'svi-question-item',
  templateUrl: './question-item.component.html',
  styleUrls: ['./question-item.component.scss']
})
export class QuestionItemComponent {
  @Input()
  text: string
  @Input()
  comment: string
  @Input()
  index: number

  expanded = false

  constructor() {}

  toggle() {
    this.expanded = !this.expanded
  }
}
