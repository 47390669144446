import { Component, Input, Output, EventEmitter } from '@angular/core'
import { Question } from '@smartvote/common'
import { coerceNumberProperty } from '@angular/cdk/coercion'

@Component({
  selector: 'svi-questionnaire-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class QuestionnaireActionsComponent {
  @Input() questions: Question[] = []
  @Input()
  set questionIndex(value: number) {
    const index = coerceNumberProperty(value)
    this.isLastQuestion = index === this.questions.length - 1
    this.isFirstQuestion = index === 0
  }
  @Input() doneDisabled = true
  @Input() doneLabel = ''
  @Output() navigate = new EventEmitter()
  @Output() done = new EventEmitter()
  isLastQuestion: boolean
  isFirstQuestion: boolean
}
