import { Component, OnInit, Inject, AfterViewInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AnswerService } from '@core/answer.service'
import { QuestionnaireQuery } from '@graphql_types'
import { Answer, Question } from '@smartvote/common'
import { Apollo } from 'apollo-angular'
import { Observable } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'
import { QUESTIONNAIRE_MODULE_CONFIG } from './questionnaire.module'
import { QuestionnaireModuleConfiguration } from './questionnaire.module.configuration'
import { LocalStorage } from '@core/tokens'
import { DeprecatedDatePipe } from '@angular/common'

export const QUESTIONNAIRE_START = 'start';
export const QUESTIONNAIRE_END = 'end';
export const RECOMMENDATION_CREATED_AT = 'recommmendation_created_at'

const query = require('graphql-tag/loader!./questionnaire.query.graphql')
@Component({
  selector: 'svi-questionnaire-page',
  templateUrl: 'questionnaire.page.html',
  styleUrls: ['questionnaire.page.scss']
})
export class QuestionnairePage implements OnInit, AfterViewInit {
  questionIndex: number
  hasAnswers
  questions: Observable<Question[]>
  userAnswers: Answer[]
  progress: string

  constructor(
    @Inject(QUESTIONNAIRE_MODULE_CONFIG) readonly config: QuestionnaireModuleConfiguration,
    @Inject(LocalStorage) private localStorage: Storage,
    private route: ActivatedRoute,
    private router: Router,
    private apollo: Apollo,
    private answerService: AnswerService
  ) {}

  ngOnInit() {
    this.questions = this.route.queryParamMap.pipe(
      tap(params => {
        this.questionIndex = parseInt(params.get('idx'), 10) || 0
      }),
      switchMap(() => this._getQuery()),
      map(({ data, loading }) => {
        const questions = data.questionnaire.questions
        this.userAnswers = this.answerService.getUserAnswersAsArray(questions)
        this.hasAnswers = this.userAnswers.some(a => a.value >= 0)
        this.progress = `${this.questionIndex + 1}/${questions.length}`
        return questions
      })
    ) as any
  }

  ngAfterViewInit() {
    this._setStart()
  }

  changeAnswer(answer: Answer) {
    this.hasAnswers = this.userAnswers.some(a => a.value >= 0)
    this.answerService.saveAnswer(answer)
  }

  navigate(direction) {
    const newQuestionIndex = this.questionIndex + parseInt(direction, 10)
    this.jumpToQuestion(newQuestionIndex)
  }
  jumpToQuestion(newQuestionIndex) {
    if (this.userAnswers[this.questionIndex].value < -9) {
      this.answerService.saveAnswer(
        Object.assign(this.userAnswers[this.questionIndex], { value: -9 })
      )
    }
    this.router.navigate(['matching', 'questionnaire'], {
      queryParams: { idx: newQuestionIndex },
      replaceUrl: true
    })
  }

  showResults() {
    this._setEnd();
    this.router.navigate(['matching', 'questions-about-you'], { queryParams: { fwd: true } })
  }

  private _getQuery() {
    return this.apollo.query<QuestionnaireQuery>({
      query
    })
  }

  private _setStart() {
    const created_rec = this.localStorage.getItem(RECOMMENDATION_CREATED_AT)
    const questionnaire_start = this.localStorage.getItem(QUESTIONNAIRE_START)
    if (!questionnaire_start || Number(created_rec) > Date.parse(questionnaire_start)) {
      this.localStorage.setItem(RECOMMENDATION_CREATED_AT, '0')
      this.localStorage.setItem(QUESTIONNAIRE_START, new Date().toISOString())
    }
  }
  private _setEnd() {
    const created_rec = this.localStorage.getItem(RECOMMENDATION_CREATED_AT)
    if (!this.localStorage.getItem(QUESTIONNAIRE_END) || !created_rec || created_rec === '0') {
      this.localStorage.setItem(QUESTIONNAIRE_END , new Date().toISOString())
    }
  }

}
