/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-photo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./profile-photo.component";
var styles_ProfilePhotoComponent = [i0.styles];
var RenderType_ProfilePhotoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfilePhotoComponent, data: {} });
export { RenderType_ProfilePhotoComponent as RenderType_ProfilePhotoComponent };
export function View_ProfilePhotoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "photo"]], [[4, "background-image", null]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "editable": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "photo"; var currVal_2 = _ck(_v, 2, 0, _co.editable); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.photoUrl; _ck(_v, 0, 0, currVal_0); }); }
export function View_ProfilePhotoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-profile-photo", [], null, null, null, View_ProfilePhotoComponent_0, RenderType_ProfilePhotoComponent)), i1.ɵdid(1, 49152, null, 0, i3.ProfilePhotoComponent, [], null, null)], null, null); }
var ProfilePhotoComponentNgFactory = i1.ɵccf("svi-profile-photo", i3.ProfilePhotoComponent, View_ProfilePhotoComponent_Host_0, { photoUrl: "photoUrl", editable: "editable" }, {}, []);
export { ProfilePhotoComponentNgFactory as ProfilePhotoComponentNgFactory };
