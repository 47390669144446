<div class="svi-database-filter-group">
  <form [formGroup]="form">
    <div class="svi-database-filter-sub-group">
      <select #selectElection *ngIf="elections && elections.length > 1" (change)="resetDistrict()" class="form-control" formControlName="election">
        <option value="" i18n>Select Election</option>
        <ng-container *ngIf="elections && elections.length > 1">
        <option  *ngFor="let election of elections" [value]="election.id">{{
          election.name
        }}</option>
        </ng-container>
      </select>
    </div>
    <ng-container *ngIf="!electionFilterOnly && election">
      <div class="svi-database-filter-sub-group">
        <select #selectDistrict  class="form-control" formControlName="district" [attr.disabled]="districts.length === 0 ? true : null">
          <option value="" i18n="@@filter.selectDistrict">Select District</option>
        <option *ngFor="let district of districts" [value]="district.id">{{
          district.name
        }}</option>
      </select>
      <input type="text" class="form-control" placeholder="Name" i18n-placeholder="@@filter.candidateNamePlaceholder" formControlName="name">
      </div>
    <div class="svi-database-filter-sub-group">
      <select *ngIf="parties" class="form-control" formControlName="party">
        <option value="" i18n="@@filter.selectParty">Select Party</option>
        <option *ngFor="let party of parties" [value]="party.id">{{party.name}}</option>
      </select>
      <select class="form-control form-control" formControlName="gender">
        <option value="" i18n="@@candidate.genderLabel">Gender</option>
        <option [value]="1" i18n="@@candidate.genderFemale">Female</option>
        <option [value]="2" i18n="@@candidate.genderMale">Male</option>
        <option [value]="3" i18n="@@candidate.genderOther">Other</option>
      </select>
      <select class="form-control form-control" formControlName="answersConfirmed">
        <option value="" i18n="@@candidate.hasAnswers">Has Answers</option>
        <option [value]="true" i18n="@@candidate.hasAnswersYes">Yes</option>
        <option [value]="false" i18n="@@candidate.hasAnswersNo">No</option>
      </select>
      <select class="form-control form-control" formControlName="incumbent">
        <option value="" i18n="@@candidate.incumbent">Incumbent</option>
        <option [ngValue]="true" i18n="@@candidate.incumbentYes">Yes</option>
        <option [ngValue]="false" i18n="@@candidate.incumbentNo">No</option>
      </select>
      <select class="form-control d-none" formControlName="elected">
        <option value="" i18n="@@candidate.elected">Elected</option>
        <option [ngValue]="true" i18n="@@candidate.electedYes">Yes</option>
        <option [ngValue]="false" i18n="@@candidate.electedNo">No</option>
      </select>
    </div>
    <div class="button-row">
      <button class="button button-primary icon-left" (click)="onSearch()" [disabled]="disabled">
        <i class="fa fa-search"></i>
        <ng-container i18n="@@search">Search</ng-container>
      </button>
      <button class="button button-primary" (click)="onReset()" [disabled]="hasStateDefaultValue">
        <ng-container i18n="@@reset">Reset</ng-container>
      </button>
    </div>
</ng-container>
  </form>
</div>