import { Component, Input } from '@angular/core'
import { SafeValue } from '@angular/platform-browser'

@Component({
  selector: 'svi-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.scss']
})
export class ProfilePhotoComponent {
  private _photoUrl: string
  @Input()
  set photoUrl(value: string) {
    if (!value) {
      this._photoUrl = ''
    } else {
      this._photoUrl = /url\(/.test(value) ? value : `url(${value})`
    }
  }
  get photoUrl(): string {
    return this._photoUrl
  }
  @Input() editable: boolean
}
