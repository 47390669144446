/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sharing-overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sharing-overlay.component";
import * as i3 from "../sharing-trigger.directive";
import * as i4 from "../sharing.service";
var styles_SharingOverlayComponent = [i0.styles];
var RenderType_SharingOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SharingOverlayComponent, data: {} });
export { RenderType_SharingOverlayComponent as RenderType_SharingOverlayComponent };
export function View_SharingOverlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "social svi-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fab fa-twitter"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sharingService.shareOnTwitter(_co.sharingInfo) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fab fa-facebook-square"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sharingService.shareOnFacebook(_co.sharingInfo) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["data-action", "share/whatsapp/share"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fab fa-whatsapp d-inline d-sm-none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-envelope"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "tooltip__arrow"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sharingService.getWhatsappUrl(_co.sharingInfo); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.sharingService.getMailUrl(_co.sharingInfo); _ck(_v, 5, 0, currVal_1); }); }
export function View_SharingOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-sharing-overlay", [], null, null, null, View_SharingOverlayComponent_0, RenderType_SharingOverlayComponent)), i1.ɵdid(1, 49152, null, 0, i2.SharingOverlayComponent, [i3.CONTAINER_DATA, i4.SharingService], null, null)], null, null); }
var SharingOverlayComponentNgFactory = i1.ɵccf("svi-sharing-overlay", i2.SharingOverlayComponent, View_SharingOverlayComponent_Host_0, {}, {}, []);
export { SharingOverlayComponentNgFactory as SharingOverlayComponentNgFactory };
