import {
  Component,
  ElementRef,
  ViewChild,
  Directive,
  HostListener,
  Input,
  AfterViewChecked,
  AfterViewInit,
  OnChanges,
  InjectionToken,
  Injector
} from '@angular/core'
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal'
import {
  Overlay,
  ConnectedPositionStrategy,
  OverlayConfig,
  ConnectedPosition,
  OverlayRef
} from '@angular/cdk/overlay'
import { SharingOverlayComponent } from './sharing-overlay/sharing-overlay.component'
import { SharingElementDirective } from './sharing-element.directive'
import { SharingInfoDirective } from './sharing-info.directive'

export const CONTAINER_DATA = new InjectionToken<{}>('CONTAINER_DATA')

// Define interface for overlay config
interface ShareButtonDialogConfig {
  hasBackdrop?: boolean
  backdropClass?: string
}

@Directive({
  selector: '[sviSharingTrigger]'
})
export class SharingTriggerDirective implements AfterViewInit {
  @Input() sharingInfo: SharingInfoDirective
  constructor(private el: ElementRef, private overlay: Overlay, private injector: Injector) {}

  ngAfterViewInit() {
    // console.log('sharing info', this.sharingInfo)
  }

  // Open an overlay
  @HostListener('click')
  open() {
    // Set config and create overlay from config
    const dialogConfig: ShareButtonDialogConfig = {
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    }
    const overlayRef = this.createOverlay(dialogConfig)

    // Create ComponentPortal and attach it to overlay
    const shareButtonPortal = new ComponentPortal(
      SharingOverlayComponent,
      null,
      this.createInjector({ sharingInfo: this.sharingInfo })
    )
    overlayRef.attach(shareButtonPortal)

    // Subscribe to backdropClick event. If event is emitted, destroy overlay
    overlayRef.backdropClick().subscribe(_ => overlayRef.dispose())
  }

  private createInjector(data): PortalInjector {
    const injectorTokens = new WeakMap()
    injectorTokens.set(CONTAINER_DATA, data)
    return new PortalInjector(this.injector, injectorTokens)
  }

  // Create full config from given partial config. Then, create overlay from full config. Then, return.

  private createOverlay(config: ShareButtonDialogConfig): OverlayRef {
    // Set where the overlay gets connected relative to the host element
    const connectedPosition: ConnectedPosition = {
      offsetX: -5,
      offsetY: -3,
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      weight: 1
    }

    // Set positionStrategy to connect relatively to share button
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.el)
      .withPositions([connectedPosition])

    // Create full config
    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close() // Set scrollStrategy to close on scrolling
    })

    // Create overlay from config and return
    return this.overlay.create(overlayConfig)
  }
}
