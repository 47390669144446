import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

import { LayoutComponent } from './layout/layout.component'
import { FooterComponent } from './footer/footer.component'
import { HeaderComponent } from './header/header.component'
import { PolicyLayoverComponent } from './policy-layover/policy-layover.component'

import { SharedModule } from '../shared/shared.module'
import { LocalStorage } from './tokens'
import { AnswerService } from './answer.service'
import { VoterIdService } from './voter-id.service'
import { TrackingService } from './tracking.service'
import { TransferState } from '@angular/platform-browser'
import { iframeSettingsProvider, IFRAME_SETTINGS } from './iframe'

const COMPONENTS = [
  LayoutComponent,
  FooterComponent,
  HeaderComponent,
  PolicyLayoverComponent
]

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [
    {
      provide: IFRAME_SETTINGS,
      useFactory: iframeSettingsProvider,
      deps: [TransferState]
    },
    {
      provide: LocalStorage,
      useValue: typeof window !== 'undefined' ? window.localStorage : { setItem() { }, getItem() { } }
    },
    AnswerService,
    VoterIdService,
    TrackingService
  ],
})
export class CoreModule {}
