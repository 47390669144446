var ProfilePhotoComponent = /** @class */ (function () {
    function ProfilePhotoComponent() {
    }
    Object.defineProperty(ProfilePhotoComponent.prototype, "photoUrl", {
        get: function () {
            return this._photoUrl;
        },
        set: function (value) {
            if (!value) {
                this._photoUrl = '';
            }
            else {
                this._photoUrl = /url\(/.test(value) ? value : "url(" + value + ")";
            }
        },
        enumerable: true,
        configurable: true
    });
    return ProfilePhotoComponent;
}());
export { ProfilePhotoComponent };
