<svi-navbar backLabel="Back to results" i18n-backLabel></svi-navbar>
<svi-page-title #title i18n>Profile</svi-page-title>
<ng-container *ngIf="data | async as data; else loading">
  <svi-profile-with-voter *ngIf="data.candidate && data.voter" [questions]="data.questions" [voter]="data.voter" [responder]="data.candidate"
    (selectedTabChanged)="onTabChanged($event)" [(tabIndex)]="tabIndex">
    <svi-profile-candidate-details [candidate]="data.candidate" [election]="data.election"></svi-profile-candidate-details>
  </svi-profile-with-voter>
  <svi-profile *ngIf="data.candidate && !data.voter" [questions]="data.questions" [voter]="data.voter" [responder]="data.candidate"
    (selectedTabChanged)="onTabChanged($event)" [(tabIndex)]="tabIndex">
    <svi-profile-candidate-details [candidate]="data.candidate" [election]="data.election"></svi-profile-candidate-details>
  </svi-profile>
  <svi-page-content *ngIf="!data.candidate" i18n>Profile not available</svi-page-content>
</ng-container>

<ng-template #loading>
  <svi-page-content>
    <svi-loading></svi-loading>
  </svi-page-content>
</ng-template>