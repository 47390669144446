import { EventEmitter } from '@angular/core';
import { QuestionType } from '@smartvote/common';
var QuestionnaireAnswerComponent = /** @class */ (function () {
    function QuestionnaireAnswerComponent() {
        this.questionType = QuestionType.StandardQuestion;
        this.responderType = 'Voter';
        this.answerChanged = new EventEmitter();
    }
    QuestionnaireAnswerComponent.prototype.answerChange = function (newValue) {
        this.answerChanged.emit(Object.assign(this.userAnswer, { value: newValue }));
    };
    QuestionnaireAnswerComponent.prototype.weightChange = function (newValue) {
        this.answerChanged.emit(Object.assign(this.userAnswer, { weight: newValue }));
    };
    Object.defineProperty(QuestionnaireAnswerComponent.prototype, "isStandard5Question", {
        get: function () {
            return this.questionType === QuestionType.Standard5Question;
        },
        enumerable: true,
        configurable: true
    });
    return QuestionnaireAnswerComponent;
}());
export { QuestionnaireAnswerComponent };
