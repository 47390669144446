import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'svi-custom-checkbox',
  templateUrl: 'custom-checkbox.component.html',
  styleUrls: ['custom-checkbox.component.scss']
})
export class CustomCheckboxComponent {
  @Input() childId: string
  @Input() checked: boolean
  @Output() valueChanged: EventEmitter<boolean> = new EventEmitter()

  changed(ev: Event) {
    this.valueChanged.emit((ev.target as HTMLInputElement).checked)
  }
}
