import { Component, Inject, AfterViewInit, PLATFORM_ID } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { FormGroup, FormBuilder, FormControl } from '@angular/forms'
import { Apollo } from 'apollo-angular'
import { isPlatformBrowser } from '@angular/common'
import suburb from '../../../../shared/assets/js/suburb.js'

import { GetElectionPartiesQuery } from '../../__generated__/types'
import { map, startWith, tap } from 'rxjs/operators'
import { Party } from '@smartvote/common'
import { Observable } from 'rxjs'
import { LocalStorage } from '../core/tokens'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { TrackingService } from '../core/tracking.service'
import { environment } from 'environments/environment'
import { QUESTIONS_ABOUT_YOU_MODULE_CONFIG } from './questions-about-you.module'
import { QuestionsAboutYouModuleConfiguration } from './questions-about-you.module.configuration'
import { OnInit } from '@angular/core'

const { GetElectionParties } = require('graphql-tag/loader!./questions-about-you.page.graphql')

export const USER_SURVEY_KEY = 'user-survey'

@Component({
  selector: 'svi-questions-about-you-page',
  templateUrl: 'questions-about-you.page.html',
  styleUrls: ['questions-about-you.page.scss']
})
export class QuestionsAboutYouPage implements OnInit, AfterViewInit {
  form: FormGroup
  parties: Observable<Party>
  options: string[] = suburb.sort((a, b) => {
    if (a.name < b.name) { return -1 }
    if (a.name > b.name) { return 1 }
    return 0
  }).map(obj => obj.name);
  filteredSuburbs: Observable<string[]>;

  constructor(
    private router: Router,
    private apollo: Apollo,
    private trackingService: TrackingService,
    route: ActivatedRoute,
    @Inject(LocalStorage) private localStorage: Storage,
    @Inject(QUESTIONS_ABOUT_YOU_MODULE_CONFIG) config: QuestionsAboutYouModuleConfiguration,
    fb: FormBuilder,
    @Inject(PLATFORM_ID) private platformId
  ) {
    const forward = coerceBooleanProperty(route.snapshot.queryParams.fwd)
    if ((forward && !!localStorage.getItem('user-survey-shown')) || !config.showUserSurvey) {
      this.continue({ replaceUrl: true, saveForm: false })
      return
    }
    this.form = fb.group({
      field_1: '',
      field_2: '',
      drop_1: '',
      drop_2: '',
      drop_3: '',
      drop_4: '',
      drop_5: '',
      drop_6: '',
      drop_7: '',
      slider_1: '',
      other_party: '',
      other_party_name: ''
    })
    this.parties = this.getPartiesFromElections().pipe(
      tap(parties => {
        parties.forEach(party => {
          this.form.addControl(`party_${party.id}`, new FormControl(''))
        })
        this.restoreState(this.form, parties)
      })
    ) as any
  }


  ngOnInit() {
    this.filteredSuburbs = this.form.get('field_1').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0)
    }
  }

  continue(
    opts: { replaceUrl?: boolean; saveForm?: boolean } = { replaceUrl: false, saveForm: false }
  ) {
    localStorage.setItem('user-survey-shown', 'true')
    const { replaceUrl, saveForm } = opts
    if (saveForm) {
      this.saveState(this.form)
    }

    // tracking
    if (saveForm) {
      this.trackingService.trackEvent('Matching', 'saveQuestionsAboutYou')
    } else if (replaceUrl) {
      this.trackingService.trackEvent('Matching', 'autoSkipQuestionsAboutYou')
    } else {
      this.trackingService.trackEvent('Matching', 'skipQuestionsAboutYou')
    }

    this.router.navigate(['matching', 'results'], { replaceUrl })
  }

  reset(name) {
    const control = this.form.controls[name]
    if (control) {
      control.reset('')
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  private _filter(value: string): string[] {
      const filterValue = value.toLowerCase();
      return this.options.filter(sub => sub.toLowerCase().includes(filterValue));
  }

  private getPartiesFromElections() {
    return this.apollo
      .query<GetElectionPartiesQuery>({
        query: GetElectionParties
      })
      .pipe(
        map(({ data }) =>
          data.elections[0].parties.filter(party =>
            party.values.some(value => value.key === 'showInUserSurvey' && value.value === 'true')
          )
        )
      )
  }

  private restoreState(form: FormGroup, parties) {
    const state = this.localStorage.getItem(USER_SURVEY_KEY)
    if (!state) {
      return
    }

    try {
      const obj = JSON.parse(state)
      form.setValue(obj)
      // Mark everything as dirty
      form.markAsDirty()
      parties.forEach(p => {
        if (obj[`party.${p.id}`]) {
          form.controls[`party.${p.id}`].markAsDirty()
        }
      })
    } catch (e) {}
  }

  private saveState(form) {
    this.localStorage.setItem(USER_SURVEY_KEY, JSON.stringify(form.value))
  }
}
