import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  LOCALE_ID,
  Inject,
  OnChanges
} from '@angular/core'
import { Router } from '@angular/router'
import {
  Question,
  Voter,
  Responder,
  getResponderType,
  isCandidate,
  Candidate,
  Party
} from '@smartvote/common'
import { DomSanitizer } from '@angular/platform-browser'
import { groupBy } from 'lodash'
import { QuestionItem, getQuestionItems } from './question-item'
import { PROFILE_MODULE_CONFIG } from './profile.module'
import { ProfileModuleConfiguration } from './profile.module.configuration'

const DEFAULT_RESPONDER_COLOR = 'f29400'

@Component({
  selector: 'svi-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent implements OnInit, OnChanges {
  // Tabs
  @Output() selectedTabChanged = new EventEmitter<number>()
  @Input() tabIndex = 0
  @Input()
  set voter(value: Voter & { isMe: boolean }) {
    if (value) {
      this._voter = {
        ...value,
        smartspider: {
          ...value.smartspider,
          options: { cssClass: 'svi-smartspider-voter' }
        }
      }
    }
  }
  get voter(): Voter & { isMe: boolean } {
    return this._voter
  }
  private _voter: Voter & { isMe: boolean }
  @Input()
  set questions(value: Question[]) {
    if (value) {
      this._questions = groupBy(value, q => q.category.name)
    }
  }
  private _questions: { [category: string]: Question[] }
  get categories() {
    return Object.keys(this._questions)
  }
  @Input()
  set responder(value: Responder) {
    if (!value) {
      return
    }
    this.responderColor = this.getResponderColor(value)
    this._responder = {
      ...value,
      smartspider: {
        ...value.smartspider,
        options: {
          fill: `#${this.responderColor}`
        }
      }
    }
    this.displayName = isCandidate(value) ? `${value.firstname} ${value.lastname}` : value.name
  }
  get responder() {
    return this._responder
  }
  private _responder: Responder
  displayName: string
  questionItems: { [category: string]: QuestionItem[] }
  responderColor = DEFAULT_RESPONDER_COLOR
  sharePath: string

  constructor(
    @Inject(PROFILE_MODULE_CONFIG) public readonly config: ProfileModuleConfiguration,
    private _sanitizer: DomSanitizer,
    private router: Router,
    @Inject(LOCALE_ID) public localeId: string
  ) {
    this.sharePath =
      '/' + this.localeId + this.router.routerState.snapshot.url.replace('matching', 'share')
  }

  ngOnInit() {
    this.questionItems = {}
    let prevQuestions = 0
    this.categories.forEach(category => {
      const questions = this._questions[category]
      this.questionItems[category] = getQuestionItems(
        questions,
        this.responder.answers,
        this.voter && this.voter.isMe ? this.voter.answers : [],
        prevQuestions
      )
      prevQuestions += questions.length
    })
  }

  ngOnChanges() {
    this.sharePath =
      '/' + this.localeId + this.router.routerState.snapshot.url.replace('matching', 'share')
  }

  getTrustedUrl(url: string) {
    return this._sanitizer.bypassSecurityTrustUrl(url)
  }

  getValue(responder: Responder, key: string, defaultValue?: any) {
    const result = responder.values.find(entry => entry.key === key)
    if (!result || !result.value) {
      return defaultValue
    }
    return result.value
  }

  private getResponderColor(responder: Responder) {
    if (getResponderType(responder) === 'Candidate') {
      return this.getValue((responder as Candidate).party, 'color', DEFAULT_RESPONDER_COLOR)
    } else if (getResponderType(responder) === 'Party') {
      return this.getValue(responder as Party, 'color', DEFAULT_RESPONDER_COLOR)
    }
  }
}
