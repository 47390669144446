/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./smartmap-methodology.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/accordion";
import * as i3 from "../../shared/accordion-item/accordion-item.component.ngfactory";
import * as i4 from "../../shared/accordion-item/accordion-item.component";
import * as i5 from "@angular/cdk/collections";
import * as i6 from "./smartmap-methodology.component";
import * as i7 from "@angular/router";
var styles_SmartmapMethodologyComponent = [i0.styles];
var RenderType_SmartmapMethodologyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SmartmapMethodologyComponent, data: {} });
export { RenderType_SmartmapMethodologyComponent as RenderType_SmartmapMethodologyComponent };
export function View_SmartmapMethodologyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "cdk-accordion", [], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.CdkAccordion, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 8, "svi-accordion-item", [["title", "More information about the smartmap"]], null, null, null, i3.View_AccordionItemComponent_0, i3.RenderType_AccordionItemComponent)), i1.ɵdid(3, 180224, null, 0, i4.AccordionItemComponent, [[3, i2.CdkAccordion], i1.ChangeDetectorRef, i5.UniqueSelectionDispatcher], { title: [0, "title"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 6, "div", [["class", "info-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "p", [["class", "hint"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The smartmap is a graphical visualisation of the political profiles of the candidates and voter based on their answers to the questionnaire. Each of the two axes represents 15 questions from the questionnaire. Each question was assigned to only one axis. Only four questions were not assigned to either of the two axes. The more similar the political profiles of a candidate and user are, the closer the points marking their positions on the smartmap will be. The horizontal axis represents the classic economic left\u2013right dimension, which focuses on the role of government intervention in the economy. The vertical axis represents social liberal-conservative dimension, which focuses on the role of government in holding up traditional values and institutions. For more information on the smartmap and which questions are associated with which axes, see the "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["class", "methodology-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToMethodology() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["methodology page"])), (_l()(), i1.ɵted(-1, null, ["."]))], function (_ck, _v) { var currVal_0 = "More information about the smartmap"; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SmartmapMethodologyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-smartmap-methodology", [], null, null, null, View_SmartmapMethodologyComponent_0, RenderType_SmartmapMethodologyComponent)), i1.ɵdid(1, 114688, null, 0, i6.SmartmapMethodologyComponent, [i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SmartmapMethodologyComponentNgFactory = i1.ɵccf("svi-smartmap-methodology", i6.SmartmapMethodologyComponent, View_SmartmapMethodologyComponent_Host_0, {}, {}, []);
export { SmartmapMethodologyComponentNgFactory as SmartmapMethodologyComponentNgFactory };
