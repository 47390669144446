import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
var SharingService = /** @class */ (function () {
    function SharingService(sanitizer) {
        this.sanitizer = sanitizer;
    }
    SharingService.prototype.openPopup = function (url) {
        window.open(url, 'pop', 'width=600, height=400, scrollbars=no');
    };
    SharingService.prototype.shareOnTwitter = function (sharingInfo) {
        var description = sharingInfo.description, url = sharingInfo.url;
        var twitterUsername = environment.sharing.twitterUsername;
        this.openPopup("https://twitter.com/intent/tweet?text=" + encodeURIComponent(description) + "&url=" + encodeURIComponent(url) + "&via=" + twitterUsername);
    };
    SharingService.prototype.shareOnFacebook = function (sharingInfo) {
        var url = sharingInfo.url;
        this.openPopup("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url));
    };
    SharingService.prototype.getWhatsappUrl = function (sharingInfo) {
        var description = sharingInfo.description, url = sharingInfo.url;
        return this.sanitizer.bypassSecurityTrustUrl("whatsapp://send?text=" + encodeURIComponent(description) + " " + encodeURIComponent(url));
    };
    SharingService.prototype.getMailUrl = function (sharingInfo) {
        var description = sharingInfo.description, url = sharingInfo.url;
        return this.sanitizer.bypassSecurityTrustUrl("mailto:?subject=" + encodeURIComponent(sharingInfo.mailSubject || sharingInfo.title) + "&body=" + encodeURIComponent((sharingInfo.mailBody || sharingInfo.description) + '\n---\n' + url));
    };
    SharingService.ngInjectableDef = i0.defineInjectable({ factory: function SharingService_Factory() { return new SharingService(i0.inject(i1.DomSanitizer)); }, token: SharingService, providedIn: "root" });
    return SharingService;
}());
export { SharingService };
