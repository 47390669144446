/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./question-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./question-item.component";
var styles_QuestionItemComponent = [i0.styles];
var RenderType_QuestionItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuestionItemComponent, data: {} });
export { RenderType_QuestionItemComponent as RenderType_QuestionItemComponent };
function View_QuestionItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "comment-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-comment"]], null, null, null, null, null))], null, null); }
export function View_QuestionItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "question-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ". ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionItemComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "comment"]], [[2, "expanded", null]], null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.comment; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.index + 1); var currVal_1 = _co.text; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _co.expanded; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.comment; _ck(_v, 7, 0, currVal_4); }); }
export function View_QuestionItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-question-item", [], null, null, null, View_QuestionItemComponent_0, RenderType_QuestionItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.QuestionItemComponent, [], null, null)], null, null); }
var QuestionItemComponentNgFactory = i1.ɵccf("svi-question-item", i3.QuestionItemComponent, View_QuestionItemComponent_Host_0, { text: "text", comment: "comment", index: "index" }, {}, ["*"]);
export { QuestionItemComponentNgFactory as QuestionItemComponentNgFactory };
