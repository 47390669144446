/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accordion-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./accordion-item.component";
import * as i3 from "@angular/cdk/accordion";
import * as i4 from "@angular/cdk/collections";
var styles_AccordionItemComponent = [i0.styles];
var RenderType_AccordionItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccordionItemComponent, data: { "animation": [{ type: 7, name: "indicatorRotate", definitions: [{ type: 0, name: "collapsed", styles: { type: 6, styles: { transform: "rotate(0deg)" }, offset: null }, options: undefined }, { type: 0, name: "expanded", styles: { type: 6, styles: { transform: "rotate(90deg)" }, offset: null }, options: undefined }, { type: 1, expr: "collapsed <=> expanded", animation: { type: 4, styles: null, timings: "225ms cubic-bezier(0.4,0.0,0.2,1)" }, options: null }], options: {} }, { type: 7, name: "bodyExpansion", definitions: [{ type: 0, name: "collapsed", styles: { type: 6, styles: { height: "0px", visibility: "hidden" }, offset: null }, options: undefined }, { type: 0, name: "expanded", styles: { type: 6, styles: { height: "*", visibility: "visible" }, offset: null }, options: undefined }, { type: 1, expr: "collapsed <=> expanded", animation: { type: 4, styles: null, timings: "225ms cubic-bezier(0.4,0.0,0.2,1)" }, options: null }], options: {} }] } });
export { RenderType_AccordionItemComponent as RenderType_AccordionItemComponent };
export function View_AccordionItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "header"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-angle-right"]], [[24, "@indicatorRotate", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", "\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "content"]], [[24, "@bodyExpansion", 0]], [[null, "@bodyExpansion.done"], [null, "@bodyExpansion.start"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("@bodyExpansion.done" === en)) {
        var pd_0 = (_co._bodyAnimation($event) !== false);
        ad = (pd_0 && ad);
    } if (("@bodyExpansion.start" === en)) {
        var pd_1 = (_co._bodyAnimation($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._getExpandedState(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co._getExpandedState(); _ck(_v, 3, 0, currVal_2); }); }
export function View_AccordionItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-accordion-item", [], null, null, null, View_AccordionItemComponent_0, RenderType_AccordionItemComponent)), i1.ɵdid(1, 180224, null, 0, i2.AccordionItemComponent, [[3, i3.CdkAccordion], i1.ChangeDetectorRef, i4.UniqueSelectionDispatcher], null, null)], null, null); }
var AccordionItemComponentNgFactory = i1.ɵccf("svi-accordion-item", i2.AccordionItemComponent, View_AccordionItemComponent_Host_0, { expanded: "expanded", disabled: "disabled", title: "title" }, { closed: "closed", opened: "opened", destroyed: "destroyed", expandedChange: "expandedChange" }, ["*"]);
export { AccordionItemComponentNgFactory as AccordionItemComponentNgFactory };
