/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./logo.component";
var styles_LogoComponent = [i0.styles];
var RenderType_LogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoComponent, data: {} });
export { RenderType_LogoComponent as RenderType_LogoComponent };
export function View_LogoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(2, "logo ", _co.size, " ", (_co.noText ? "no-text" : ""), ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_LogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-logo", [], null, null, null, View_LogoComponent_0, RenderType_LogoComponent)), i1.ɵdid(1, 49152, null, 0, i2.LogoComponent, [], null, null)], null, null); }
var LogoComponentNgFactory = i1.ɵccf("svi-logo", i2.LogoComponent, View_LogoComponent_Host_0, { size: "size", noText: "noText" }, {}, []);
export { LogoComponentNgFactory as LogoComponentNgFactory };
