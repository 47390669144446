import { Component, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core'
import { Observable } from 'apollo-link'
import { Question, Voter, Party } from '@smartvote/common'
import { ActivatedRoute, Router } from '@angular/router'
import { switchMap, map } from 'rxjs/operators'
import { Apollo } from 'apollo-angular'

import { GetPartyDetailsQuery, GetPartyDetailsWithVoterQuery } from '../../__generated__/types'
import { combineLatest } from 'rxjs'
import { VoterIdService } from '../core/voter-id.service'
import { isPlatformBrowser } from '@angular/common'
const {
  GetPartyDetails,
  GetPartyDetailsWithVoter
} = require('graphql-tag/loader!./details.page.graphql')

export interface PartyDetails {
  party: Party
  questions: Question[]
  voter: Voter
}

@Component({
  selector: 'svi-party-details',
  templateUrl: 'party-details.page.html'
})
export class PartyDetailsPage implements AfterViewInit {
  tabIndex = 0
  data: Observable<PartyDetails>
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    voterIdService: VoterIdService,
    private apollo: Apollo,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.data = combineLatest(route.params, route.queryParams).pipe(
      switchMap(([params, queryParams]) => {
        this.tabIndex = parseInt(queryParams['tab'], 10) || 0
        if (queryParams.rid) {
          return this._getDataWithVoter(params.id, queryParams.rid)
        } else {
          return this._getData(params.id)
        }
      }),
      map(({ data }) => {
        const { party, questionnaire, recommendation } = data as any
        // Deep copy hack for issue: https://github.com/apollographql/apollo-angular/issues/329
        if (recommendation) {
          return {
            party,
            questions: questionnaire.questions,
            voter: {
              ...recommendation.voter,
              isMe: voterIdService.getVoterId() === recommendation.voter.id
            }
          }
        } else {
          return {
            party,
            questions: questionnaire.questions
          }
        }
      })
    ) as any
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0)
    }
  }

  onTabChanged(index: number) {
    this._updateQueryParams({ tab: index })
  }

  private _updateQueryParams(params) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      replaceUrl: true,
      queryParamsHandling: 'merge'
    })
  }

  private _getDataWithVoter(partyId: string, recommendationId: string, electionId = '1') {
    return this.apollo.query<GetPartyDetailsWithVoterQuery>({
      query: GetPartyDetailsWithVoter,
      variables: { partyId, electionId, recommendationId }
    })
  }

  private _getData(partyId: string, electionId = '1') {
    return this.apollo.query<GetPartyDetailsQuery>({
      query: GetPartyDetails,
      variables: { partyId, electionId }
    })
  }
}
