import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { DomSanitizer } from '@angular/platform-browser'

@Injectable({
  providedIn: 'root'
})
export class SharingService {
  sanitizer: DomSanitizer
  constructor(sanitizer: DomSanitizer) {
    this.sanitizer = sanitizer
  }

  openPopup(url) {
    window.open(url, 'pop', 'width=600, height=400, scrollbars=no')
  }

  shareOnTwitter(sharingInfo) {
    const { description, url } = sharingInfo
    const { twitterUsername } = environment.sharing
    this.openPopup(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        description
      )}&url=${encodeURIComponent(url)}&via=${twitterUsername}`
    )
  }

  shareOnFacebook(sharingInfo) {
    const { url } = sharingInfo
    this.openPopup(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`)
  }

  getWhatsappUrl(sharingInfo) {
    const { description, url } = sharingInfo
    return this.sanitizer.bypassSecurityTrustUrl(
      `whatsapp://send?text=${encodeURIComponent(description)} ${encodeURIComponent(url)}`
    )
  }

  getMailUrl(sharingInfo) {
    const { description, url } = sharingInfo
    return this.sanitizer.bypassSecurityTrustUrl(
      `mailto:?subject=${encodeURIComponent(
        sharingInfo.mailSubject || sharingInfo.title
      )}&body=${encodeURIComponent(
        (sharingInfo.mailBody || sharingInfo.description) + '\n---\n' + url
      )}`
    )
  }
}
