import { Injectable, Inject, PLATFORM_ID } from '@angular/core'
import { environment } from '../../environments/environment'
import { Router, RoutesRecognized } from '@angular/router'
import { isPlatformBrowser } from '@angular/common'

import { filter } from 'rxjs/operators'

type TrackingEventCategory = 'Common' | 'Matching' | 'Profile' | 'Questionnaire'

declare var window: {
  [key: string]: any
  prototype: Window
  new (): Window
}

@Injectable()
export class TrackingService {
  private previousUrl: string
  private trackingAllPageViews: boolean

  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      window._paq = window._paq || []
      window._paq.push(['trackPageView'])
      window._paq.push(['enableLinkTracking'])
    }
  }

  trackEvent(
    eventCategory: TrackingEventCategory,
    eventAction: string,
    eventName?: string,
    eventValue?: number
  ) {
    window._paq.push(['trackEvent', eventCategory, eventAction, eventName, eventValue])
  }

  trackAllPageViews() {
    // don't subscribe twice
    if (this.trackingAllPageViews) {
      return
    }
    this.trackingAllPageViews = true

    this.router.events
      .pipe(filter(e => e instanceof RoutesRecognized))
      .subscribe((event: RoutesRecognized) => {
        const eventUrl = event.urlAfterRedirects
        this.trackPageView(eventUrl)
      })
  }

  private trackPageView(url: string, documentTitle?: string) {
    if (isPlatformBrowser(this.platformId)) {
      window._paq.push(['setCustomUrl', url])
      if (documentTitle) {
        window._paq.push(['setDocumentTitle', documentTitle])
      }
      if (this.previousUrl) {
        window._paq.push(['setReferrerUrl', this.previousUrl])
      }
      window._paq.push(['setGenerationTimeMs', 0])
      window._paq.push(['trackPageView'])
      this.previousUrl = url
    }
  }

  init() {
    if (isPlatformBrowser(this.platformId)) {
      const u = environment.matomo.url
      window._paq.push(['setTrackerUrl', u + '/piwik.php'])
      window._paq.push(['setSiteId', environment.matomo.siteId.toString()])
      const d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0]
      g.type = 'text/javascript'
      g.async = true
      g.defer = true
      g.src = u + '/piwik.js'
      s.parentNode.insertBefore(g, s)
    }
  }
}
