import { Component } from '@angular/core'

@Component({
  selector: 'svi-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  constructor() {}
}
