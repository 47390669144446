import { Router, NavigationEnd } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var LastRouteService = /** @class */ (function () {
    function LastRouteService(router) {
        var _this = this;
        this.router = router;
        this.currentUrl = this.router.url;
        router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this.previousUrl = _this.currentUrl;
                _this.currentUrl = event.url;
            }
        });
    }
    LastRouteService.prototype.getPreviousUrl = function () {
        return this.previousUrl;
    };
    LastRouteService.ngInjectableDef = i0.defineInjectable({ factory: function LastRouteService_Factory() { return new LastRouteService(i0.inject(i1.Router)); }, token: LastRouteService, providedIn: "root" });
    return LastRouteService;
}());
export { LastRouteService };
