/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./chart-legend-item.component";
var styles_ChartLegendItemComponent = [];
var RenderType_ChartLegendItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChartLegendItemComponent, data: {} });
export { RenderType_ChartLegendItemComponent as RenderType_ChartLegendItemComponent };
export function View_ChartLegendItemComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "svi-chard-legend-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, ":svg:svg", [["height", "20"], ["style", "vertical-align: bottom;"], ["viewBox", "0 0 20 20"], ["width", "20"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, ":svg:g", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, ":svg:circle", [["cx", "10"], ["cy", "10"], ["r", "10"], ["stroke", "white"], ["stroke-width", "0"]], [[4, "fill", null]], null, null, null, null)), (_l()(), i0.ɵted(4, null, [" \u00A0", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.color; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.label; _ck(_v, 4, 0, currVal_1); }); }
export function View_ChartLegendItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-chart-legend-item", [], null, null, null, View_ChartLegendItemComponent_0, RenderType_ChartLegendItemComponent)), i0.ɵdid(1, 114688, null, 0, i1.ChartLegendItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChartLegendItemComponentNgFactory = i0.ɵccf("svi-chart-legend-item", i1.ChartLegendItemComponent, View_ChartLegendItemComponent_Host_0, { label: "label", color: "color" }, {}, []);
export { ChartLegendItemComponentNgFactory as ChartLegendItemComponentNgFactory };
