/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./partners.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/navbar/navbar.component.ngfactory";
import * as i3 from "../../shared/navbar/navbar.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "../../core/tokens";
import * as i7 from "../../shared/page-title/page-title.component.ngfactory";
import * as i8 from "../../shared/page-title/page-title.component";
import * as i9 from "../../shared/page-content/page-content.component.ngfactory";
import * as i10 from "../../shared/page-content/page-content.component";
import * as i11 from "./partners.page";
var styles_PartnersPage = [i0.styles];
var RenderType_PartnersPage = i1.ɵcrt({ encapsulation: 0, styles: styles_PartnersPage, data: {} });
export { RenderType_PartnersPage as RenderType_PartnersPage };
export function View_PartnersPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(1, 49152, null, 0, i3.NavbarComponent, [i4.Location, i5.Router, i6.LocalStorage], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "svi-page-title", [], null, null, null, i7.View_PageTitleComponent_0, i7.RenderType_PageTitleComponent)), i1.ɵdid(3, 49152, null, 0, i8.PageTitleComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["Partners"])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "svi-page-content", [], null, null, null, i9.View_PageContentComponent_0, i9.RenderType_PageContentComponent)), i1.ɵdid(6, 49152, null, 0, i10.PageContentComponent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 4, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Media partner"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "partner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "a", [["class", "logo canberra-times"], ["href", "https://www.canberratimes.com.au/"], ["target", "_blank"]], null, null, null, null, null))], null, null); }
export function View_PartnersPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-partners", [], null, null, null, View_PartnersPage_0, RenderType_PartnersPage)), i1.ɵdid(1, 4243456, null, 0, i11.PartnersPage, [i1.PLATFORM_ID], null, null)], null, null); }
var PartnersPageNgFactory = i1.ɵccf("svi-partners", i11.PartnersPage, View_PartnersPage_Host_0, {}, {}, []);
export { PartnersPageNgFactory as PartnersPageNgFactory };
