import { Injectable, Inject } from '@angular/core'
import { Observable } from 'rxjs'
import { Answer, Question, Questionnaire } from '@smartvote/common'
import { LocalStorage } from './tokens'

interface UserAnswer {
  questionId: string
  value: number
  weight: number
}
interface UserAnswerMap {
  [id: string]: UserAnswer
}

@Injectable()
export class AnswerService {
  private _userAnswers: UserAnswerMap
  constructor(@Inject(LocalStorage) private localStorage: Storage) {
    // load answers from localStorage in memory
    this._userAnswers = this._loadAnswersFromLocalStorage()
  }

  getAnswer(userAnswer: UserAnswer, question?): Answer {
    const result = {
      value: userAnswer ? userAnswer.value : -10,
      weight: userAnswer ? userAnswer.weight : 1,
      questionId: userAnswer ? userAnswer.questionId : question.id
    } as Answer
    if (question) {
      result.question = question
    }
    if (!result.questionId) {
      throw new Error('Getting user answer. `questionId` is missing')
    }
    return result
  }

  saveAnswer(answer: Answer) {
    if (!(answer && answer.questionId)) {
      throw new Error('Saving answer. `questionId` is missing!')
    }
    const userAnswer: UserAnswer = {
      questionId: answer.questionId,
      value: answer.value,
      weight: answer.weight || 1 // make sure answer.weight is set
    }
    // update _userAnswers
    if (answer.value >= -9) {
      this._userAnswers['question-' + answer.questionId] = userAnswer
    } else {
      this._userAnswers['question-' + answer.questionId] = undefined
    }
    // update localStorage
    this._saveAnswerInLocalStorage(userAnswer)
  }

  getUserAnswersAsArray(questions): Answer[] {
    const answers: Answer[] = []
    questions.forEach(q => {
      const userAnswer = this._userAnswers['question-' + q.id]
      answers.push(this.getAnswer(userAnswer, q))
    })
    return answers.filter(a => a.value !== -1)
  }

  getAnswers(): Answer[] {
    const answers = []
    return Object.keys(this._userAnswers).map(key => {
      const userAnswer = this._userAnswers[key]
      return this.getAnswer(userAnswer)
    })
  }

  private _loadAnswersFromLocalStorage(): UserAnswerMap {
    const answers: UserAnswerMap = {}
    const keys = Object.keys(this.localStorage)
    let i = keys.length

    while (i--) {
      if (keys[i].startsWith('question-')) {
        const answer = JSON.parse(this.localStorage.getItem(keys[i]))
        if (typeof answer.value === 'string') {
          answer.value = parseInt(answer.value, 10)
        }
        if (typeof answer.weight === 'string') {
          answer.weight = parseInt(answer.weight, 10)
        }
        answers[keys[i]] = answer
      }
    }

    return answers
  }

  private _saveAnswerInLocalStorage(answer: UserAnswer) {
    if (answer.value >= 0) {
      this.localStorage.setItem('question-' + answer.questionId, JSON.stringify(answer))
    } else {
      this.localStorage.removeItem('question-' + answer.questionId)
    }
  }
}
