import { EventEmitter } from '@angular/core';
import { coerceNumberProperty } from '@angular/cdk/coercion';
var QuestionnaireActionsComponent = /** @class */ (function () {
    function QuestionnaireActionsComponent() {
        this.questions = [];
        this.doneDisabled = true;
        this.doneLabel = '';
        this.navigate = new EventEmitter();
        this.done = new EventEmitter();
    }
    Object.defineProperty(QuestionnaireActionsComponent.prototype, "questionIndex", {
        set: function (value) {
            var index = coerceNumberProperty(value);
            this.isLastQuestion = index === this.questions.length - 1;
            this.isFirstQuestion = index === 0;
        },
        enumerable: true,
        configurable: true
    });
    return QuestionnaireActionsComponent;
}());
export { QuestionnaireActionsComponent };
